import { styled } from "@linaria/react";
import { type FunctionComponent } from "react";

import NarrativeAreaChart from "~/components/nrms/NarrativeAreaChart";
import { TruncatedLineClampText } from "~/components/text";
import type { NrmsConversationThemeMetricsDTO } from "~/dto/nrmsConversationThemeMetrics";
import { text } from "~/styles/typography";
import { formatCompactNumber } from "~/utils/numberUtils";
import { pluralize } from "~/utils/stringUtils";

import Section, { Heading } from "../Section";

import { EvergreenThemeLabels } from "./constants";

type ThemeInModuleProps = Omit<NrmsConversationThemeMetricsDTO, "id"> & {
  order: number;
  timeRangeDisplay: string;
};

const ModuleWrapper = styled(Section)<{ order: number }>`
  display: flex;
  flex-direction: column;
  border: 1px solid var(--border-color-primary);
  border-radius: var(--border-radius-sm);
  overflow: hidden;
  border-top: 6px solid ${({ order }) => `var(--nrms-theme-${order})`};
`;

const ModuleContentWrapper = styled.div`
  flex-grow: 1;
  display: grid;
  grid-template-rows: auto auto 1fr;
  padding: var(--spacing-xl) var(--spacing-3xl) var(--spacing-3xl)
    var(--spacing-3xl);

  [data-auto-header] {
    ${text.sm.medium};
    margin-bottom: var(--spacing-lg);
  }
`;

const Summary = styled(TruncatedLineClampText)`
  ${text.xs.regular}
  color: var(--text-color-primary);
`;

const ModuleChartWrapper = styled(Section)`
  flex-grow: 1;
  display: grid;
  gap: var(--spacing-lg);
  padding: 0 var(--spacing-xl) var(--spacing-xl) var(--spacing-xl);

  [data-auto-header] {
    padding: 0 var(--spacing-md);
    ${text.xs.medium};
  }
`;

const ThemeInModule: FunctionComponent<ThemeInModuleProps> = ({
  order,
  theme_name,
  theme_summary,
  volume_history,
  volume_raw_date_range,
  timeRangeDisplay,
}) => {
  const totalPosts = formatCompactNumber(volume_raw_date_range ?? 0);
  const postCountStr = `${totalPosts} ${pluralize(
    volume_raw_date_range ?? 0,
    "Post",
    undefined,
    true,
  )} - ${timeRangeDisplay}`;

  return (
    <ModuleWrapper
      as="article"
      data-testid={`theme-in-module_${theme_name}`}
      id={`theme-in-module_${theme_name}`}
      order={order}
    >
      <ModuleContentWrapper>
        <Heading>{EvergreenThemeLabels[theme_name]}</Heading>
        <Summary lines={4} text={theme_summary ?? ""}>
          {theme_summary}
        </Summary>
      </ModuleContentWrapper>
      <ModuleChartWrapper as="article">
        <Heading id="volume-trend">{postCountStr}</Heading>
        <NarrativeAreaChart
          ariaLabelledby="volume-trend"
          volume_history={volume_history}
        />
      </ModuleChartWrapper>
    </ModuleWrapper>
  );
};

export default ThemeInModule;
