import { styled } from "@linaria/react";
import { type FunctionComponent, useState, useCallback } from "react";

import CenteredLayout from "~/components/CenteredLayout";
import { ProfileDialogContextProvider } from "~/components/ProfileDialogContext";
import Section, { Heading } from "~/components/Section";
import Icon from "~/components/library/Icon";
import LoadingIndicator from "~/components/library/LoadingIndicator/LoadingIndicator";
import { Option, Select } from "~/components/library/Select";
import ThemeInModule from "~/components/nrms/ThemeInModule";
import type { NrmsConversationThemeMetricsDTO } from "~/dto/nrmsConversationThemeMetrics";
import { useNrmsConversationData } from "~/hooks/useNrmsConversations";
import { display, text } from "~/styles/typography";
import { formatNRMSDateRangeText } from "~/utils/datetime";

import ThemeRadarBarChart from "./ThemeRadarBarChart";

const orderByThemeName: NrmsConversationThemeMetricsDTO["theme_name"][] = [
  "DEI",
  "BOYCOTT",
  "UNION",
  "ELECTION_DENIAL",
  "FINANCE",
  "SHORTSELLING",
  "QANON",
];

const timeRangeOptions: Record<string, string> = {
  LAST_DAY: "Past Day",
  LAST_WEEK: "Past Week",
  LAST_MONTH: "Past Month",
};

function orderTheme(objects: NrmsConversationThemeMetricsDTO[]) {
  const orderedDict: { [key: string]: number } = {};
  orderByThemeName.forEach((theme, index) => {
    orderedDict[theme] = index;
  });
  return objects
    .toSorted(
      (a, b) =>
        orderByThemeName.indexOf(a.theme_name) -
        orderByThemeName.indexOf(b.theme_name),
    )
    .slice(0, 6);
}

const SectionWrapper = styled(Section)`
  width: 100%;
  height: 100%;
  padding: var(--spacing-xl) var(--spacing-2xl);
`;

const HeaderContainer = styled.div`
  padding-top: var(--spacing-xl);
  padding-left: var(--spacing-xl);
  padding-bottom: var(--spacing-4xl);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-lg);
  margin-bottom: var(--spacing-xl);
`;

const SectionTitle = styled(Heading)`
  ${display.xs.regular}
  color: var(--text-color-primary);
  padding-right: var(--spacing-8xl);
`;

const ChartContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`;

const ModuleWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--spacing-xl);
  padding: 0 var(--spacing-8xl);

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    padding: 0;
    grid-template-columns: repeat(3, 1fr);
  }
`;

const NoResultMessage = styled(CenteredLayout)`
  flex-direction: column;
  gap: var(--spacing-lg);
  margin-top: var(--spacing-6xl);

  [data-icon] {
    width: 48px;
    height: 48px;
    color: var(--color-utility-gray-500);
  }

  > span {
    ${text.lg.regular};
  }
`;

const Dropdown = styled(Select)`
  padding-left: var(--spacing-8xl);
  padding-right: var(--spacing-lg);
  height: var(--spacing-8xl);
  min-width: 300px;
  overflow: hidden;
  button {
    border-radius: var(--border-radius-sm);
    height: 100%;
    width: 100%;
  }
`;

const DropdownOption = styled.div`
  display: flex;
  flex-direction: column;
`;

const TimeRangeDisplay = styled.span`
  font-size: ${text.xs.regular.fontSize};
  color: var(--text-color-placeholder);
`;

interface TimeRangeDropdownProps {
  selectedTimeRange: string;
  setSelectedTimeRange: (value: string) => void;
}

const TimeRangeDropdown: FunctionComponent<TimeRangeDropdownProps> = ({
  selectedTimeRange,
  setSelectedTimeRange,
}) => {
  const timeRangeDisplayText = formatNRMSDateRangeText(selectedTimeRange);
  const [isOpen, setIsOpen] = useState(false);

  const handleSelectionChange = useCallback(
    (key: string | number) => {
      setSelectedTimeRange(String(key));
    },
    [setSelectedTimeRange],
  );

  return (
    <Dropdown
      name="timeRange"
      onOpenChange={setIsOpen}
      onSelectionChange={handleSelectionChange}
      selectedKey={selectedTimeRange}
    >
      {/* special dropdown option for the currently-selected time range because When the dropdown is in the selected state it’s supposed to include the date range */}
      <Option key={selectedTimeRange} id={selectedTimeRange}>
        <DropdownOption>
          <span>{timeRangeOptions[selectedTimeRange]}</span>
          {!isOpen && (
            <TimeRangeDisplay>{timeRangeDisplayText}</TimeRangeDisplay>
          )}
        </DropdownOption>
      </Option>

      {Object.entries(timeRangeOptions).map(([key, value]) =>
        key !== selectedTimeRange ? (
          <Option key={key} id={key}>
            <span>{value}</span>
          </Option>
        ) : null,
      )}
    </Dropdown>
  );
};

const useNarrativeActivity = () => {
  const [selectedTimeRange, setSelectedTimeRange] = useState("LAST_WEEK");
  const { isLoading: isNrmsConversationLoading, data } =
    useNrmsConversationData(selectedTimeRange);
  const orderedTheme = orderTheme(data);
  const themeData = orderedTheme.map((item, index) => ({
    name: item.theme_name,
    value: item.volume_raw_date_range ?? 0,
    color: `var(--nrms-theme-${index})`,
  }));

  // Replace with real total number of posts
  const totalPosts = data.length > 0 ? data[0].total_posts_date_range ?? 0 : 0;

  return {
    data,
    isNrmsConversationLoading,
    orderedTheme,
    themeData,
    totalPosts,
    selectedTimeRange,
    setSelectedTimeRange,
  };
};

const NarrativeActivity: FunctionComponent = () => {
  const {
    data,
    isNrmsConversationLoading,
    orderedTheme,
    themeData,
    totalPosts,
    selectedTimeRange,
    setSelectedTimeRange,
  } = useNarrativeActivity();

  if (isNrmsConversationLoading) {
    return <LoadingIndicator />;
  }

  return (
    <ProfileDialogContextProvider>
      <SectionWrapper>
        <HeaderContainer>
          <SectionTitle>Topic Radar</SectionTitle>
          {data.length > 0 && (
            <ChartContainer>
              <ThemeRadarBarChart data={themeData} total={totalPosts} />
            </ChartContainer>
          )}
          <TimeRangeDropdown
            selectedTimeRange={selectedTimeRange}
            setSelectedTimeRange={setSelectedTimeRange}
          />
        </HeaderContainer>

        {data.length > 0 ? (
          <ModuleWrapper>
            {orderedTheme.map(({ id, ...dataProps }, index) => (
              <ThemeInModule
                key={id}
                {...dataProps}
                order={index}
                timeRangeDisplay={timeRangeOptions[selectedTimeRange]}
              />
            ))}
          </ModuleWrapper>
        ) : (
          <NoResultMessage>
            <Icon family="untitled" name="x-circle" />
            <span>No Narrative Activity Themes Found</span>
          </NoResultMessage>
        )}
      </SectionWrapper>
    </ProfileDialogContextProvider>
  );
};

export default NarrativeActivity;
