import { styled } from "@linaria/react";
import {
  useContext,
  createContext,
  useState,
  useCallback,
  type ContextComponent,
  type FunctionComponent,
  useMemo,
} from "react";
import Draggable, {
  type DraggableEvent,
  type DraggableData,
} from "react-draggable";

import {
  CoordinationPopUp,
  type CoordinationPopUpProps,
} from "~/pages/coordination";

import Dialog, { DialogHeading } from "./Dialog";
import { GraphPopup, type GraphPopupProps } from "./GraphPopup";
import URLNodePopUp, { type URLNodePopUpProps } from "./URLNodePopUp";
import UserNodePopUp, { type UserNodePopUpProps } from "./UserNodePopUp";

const StyledDialog = styled(Dialog)`
  box-shadow: var(--shadow-lg);
  top: 15%;
  left: 30%;
  &[data-dragging="true"] {
    ${DialogHeading} {
      cursor: grabbing;
    }
  }

  ${DialogHeading} {
    cursor: grab;
  }
`;

export type ProfilePopupProps =
  | (UserNodePopUpProps & { popupType: "user" })
  | (URLNodePopUpProps & { popupType: "url" })
  | (CoordinationPopUpProps & { popupType: "coordination" })
  | (GraphPopupProps & { popupType: "graph" });

// credit to https://stackoverflow.com/questions/67664627/validate-multiple-types-in-typeguard
export function isProfilePopupType<T extends ProfilePopupProps["popupType"]>(
  props: ProfilePopupProps | undefined,
  popupType: T,
): props is Extract<ProfilePopupProps, { popupType: T }> {
  return props?.popupType === popupType;
}

interface ProfileDialogContextShape {
  profilePopupData: ProfilePopupProps | undefined;
  clearProfileData: () => void;
  setUserData: (props: UserNodePopUpProps) => void;
  setURLData: (props: URLNodePopUpProps) => void;
  setCoordinationData: (props: CoordinationPopUpProps) => void;
  setGraphData: (props: GraphPopupProps) => void;
}

const profileDialogContext = createContext<
  ProfileDialogContextShape | undefined
>(undefined);

export function useProfileDialogContext() {
  const context = useContext(profileDialogContext);
  if (!context) {
    throw new Error(
      "useProfileDialogContext must be used within a ProfileDialogContextProvider",
    );
  }
  return context;
}

export const ProfileDialogContextProvider: ContextComponent = ({
  children,
}) => {
  const [profilePopupData, setProfilePopupData] = useState<ProfilePopupProps>();
  const [dialogPosition, setDialogPosition] = useState<[number, number]>([
    0, 0,
  ]);
  const [dragging, setDragging] = useState<boolean>(false);
  const onStopDrag = useCallback((_e: DraggableEvent, data: DraggableData) => {
    setDialogPosition([data.x, data.y]);
    setDragging(false);
  }, []);
  const onStartDrag = useCallback(() => {
    setDragging(true);
  }, []);
  const clearProfileData = useCallback(() => {
    setProfilePopupData(undefined);
  }, []);

  const setUserData = useCallback((props: UserNodePopUpProps) => {
    setProfilePopupData({ popupType: "user", ...props });
  }, []);

  const setURLData = useCallback((props: URLNodePopUpProps) => {
    setProfilePopupData({ popupType: "url", ...props });
  }, []);

  const setCoordinationData = useCallback((props: CoordinationPopUpProps) => {
    setProfilePopupData({ popupType: "coordination", ...props });
  }, []);

  const setGraphData = useCallback((props: GraphPopupProps) => {
    setProfilePopupData({ popupType: "graph", ...props });
  }, []);

  const contextValue: ProfileDialogContextShape = useMemo(
    () => ({
      profilePopupData,
      clearProfileData,
      setUserData,
      setURLData,
      setCoordinationData,
      setGraphData,
    }),
    [
      profilePopupData,
      clearProfileData,
      setUserData,
      setURLData,
      setCoordinationData,
      setGraphData,
    ],
  );

  return (
    <profileDialogContext.Provider value={contextValue}>
      <Draggable
        bounds="body"
        defaultPosition={{ x: dialogPosition[0], y: dialogPosition[1] }}
        handle=".dialog-heading"
        onStart={onStartDrag}
        onStop={onStopDrag}
      >
        <StyledDialog
          data-dragging={dragging}
          isOpen={Boolean(profilePopupData)}
        >
          {profilePopupData && (
            <PopupSwitch profilePopupData={profilePopupData} />
          )}
        </StyledDialog>
      </Draggable>
      {children}
    </profileDialogContext.Provider>
  );
};

interface PopUpSwitchProps {
  profilePopupData: ProfilePopupProps;
}

const PopupSwitch: FunctionComponent<PopUpSwitchProps> = (props) => {
  const { profilePopupData } = props;
  const { popupType } = profilePopupData;

  switch (popupType) {
    case "user":
      return <UserNodePopUp {...profilePopupData} />;
    case "url":
      return <URLNodePopUp {...profilePopupData} />;
    case "coordination":
      return <CoordinationPopUp {...profilePopupData} />;
    case "graph":
      return <GraphPopup {...profilePopupData} />;
    default:
      return null;
  }
};
