import { z } from "zod";

import { MetadataValidator } from "~/dto/metadata";
import {
  NrmsConversationThemeMetricsListWithEnvelope,
  type NrmsConversationThemeMetricsDTO,
} from "~/dto/nrmsConversationThemeMetrics";

import { useCurrentUser } from "./useCurrentUser";
import { useRemoteCollection, useRemoteObject } from "./useRemoteData";
import { defaultGetFetcherFactory } from "./useRemoteData/utils";

export function useNrmsConversationData(date_range?: string) {
  const { hasPermission } = useCurrentUser();
  const params = new URLSearchParams();

  if (date_range) {
    params.append("date_range", date_range);
  }
  const apiEndpoint = hasPermission("VIEW_NRMS_CONVERSATION")
    ? `/api/v2/conversations/nrms?${params}`
    : undefined;

  return useRemoteCollection<NrmsConversationThemeMetricsDTO>(apiEndpoint, {
    getFetcher: defaultGetFetcherFactory,
    schemaValidator: NrmsConversationThemeMetricsListWithEnvelope,
  });
}

const NRMS_Enabled_Schema = z.object({
  data: z.object({ enabled: z.boolean() }),
  metadata: MetadataValidator,
});
type NRMS_Enabled = z.infer<typeof NRMS_Enabled_Schema>;

export function useNrmsConversationEnabled() {
  const { hasPermission } = useCurrentUser();

  const apiEndpoint = hasPermission("VIEW_NRMS_CONVERSATION")
    ? `/api/v2/conversations/nrms_enabled`
    : undefined;

  return useRemoteObject<NRMS_Enabled>(apiEndpoint, {
    schemaValidator: NRMS_Enabled_Schema,
  });
}
