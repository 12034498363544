import { styled } from "@linaria/react";
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  type FunctionComponent,
} from "react";

import { LoadingIndicator } from "~/components";
import { UserExternalLinkComponent } from "~/components/ConnectionPreview";
import Section, { Heading } from "~/components/Section";
import Button, { ButtonKind, ButtonSize } from "~/components/library/Button";
import Icon from "~/components/library/Icon";
import { type CoordinationClusterProfileDTO } from "~/dto/coordinationClusterProfile";
import { useConversationFilters } from "~/hooks/useConversationFilters";
import { useClusterProfile } from "~/hooks/useCoordination";
import { text } from "~/styles/typography";

import { EVIDENCES, type Evidence } from "../constants";
import { type ClusterCoordination } from "../types";

import { Evidences } from "./Evidences";
import { ErrorContainer, NoDataContainer, PopUpBase } from "./SharedComponents";

const MAX_NUM_AVATAR = 3;
const IMG_SIZE = 35;
const IMG_EXPANDED_SIZE = 20;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);

  > img {
    display: block;
    border-radius: 50%;
    width: ${IMG_SIZE}px;
    height: ${IMG_SIZE}px;

    &[data-expanded="true"] {
      width: ${IMG_EXPANDED_SIZE}px;
      height: ${IMG_EXPANDED_SIZE}px;
    }
  }

  [data-icon] {
    width: ${IMG_SIZE}px;
    height: ${IMG_SIZE}px;

    &[data-expanded="true"] {
      width: ${IMG_EXPANDED_SIZE}px;
      height: ${IMG_EXPANDED_SIZE}px;
    }
  }
`;

const ClosedContainer = styled(Section)`
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);

  > ul {
    height: 100%;
    display: flex;
    width: 80%;
    gap: var(--spacing-md);

    > li {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      flex: 1;
      min-width: 0;

      > button {
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: var(--font-size-700);
        font-weight: var(--font-weight-regular);
        width: 100%;
      }
    }
  }

  > div:nth-child(2) {
    display: flex;
    flex: auto;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    > button {
      text-wrap: nowrap;
    }
  }
`;

const CircleDiv = styled.div`
  width: ${IMG_SIZE}px;
  height: ${IMG_SIZE}px;
  border-radius: 50%;
  background-color: var(--color-gray-400);
  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    ${text.sm.regular};
    ${text.md.semibold};
    color: var(--color-white);
  }
`;

const ExpandedContainer = styled(Section)`
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);
  border: var(--border-group);
  border-color: var(--color-gray-200);
  border-radius: var(--border-radius-default);
  padding: var(--spacing-lg);
  gap: var(--spacing-md);

  > div {
    display: flex;
    justify-content: space-between;
  }

  > ul {
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    max-height: 150px;

    > li {
      display: flex;
      align-items: center;
      width: 50%;
      gap: var(--spacing-xs);
      padding: var(--spacing-xs) 0;

      > button {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: var(--font-size-700);
      }
    }
  }
`;

const StyledHeading = styled(Heading)`
  ${text.md.semibold};
  ${text.xs.regular};
`;

interface ImageBoxProps {
  account: CoordinationClusterProfileDTO["accounts"][number];
  isExpanded: boolean;
}

const ImageBox: FunctionComponent<ImageBoxProps> = (props) => {
  const { account, isExpanded } = props;

  const [imgFailedToLoad, setImgFailedToLoad] = useState(false);

  useEffect(() => {
    setImgFailedToLoad(!account.avatar_url);
  }, [account.avatar_url]);

  const imageError = useCallback(() => {
    setImgFailedToLoad(true);
  }, []);

  return (
    <ImageContainer>
      {!imgFailedToLoad && account.avatar_url ? (
        <img
          alt={`${account.rid} Avatar`}
          aria-hidden="true"
          data-expanded={isExpanded}
          onError={imageError}
          src={account.avatar_url}
        />
      ) : (
        <Icon
          aria-label={`User ${account.rid} default image`}
          data-expanded={isExpanded}
          family="untitled"
          name="user-01"
        />
      )}
    </ImageContainer>
  );
};

interface ClusterAccountsProps {
  conversationId: string;
  clusterId: string;
  data: CoordinationClusterProfileDTO;
}

const ClusterAccounts: FunctionComponent<ClusterAccountsProps> = (props) => {
  const { data, clusterId, conversationId } = props;
  const { accounts } = data;

  const [expandAccounts, setExpandAccounts] = useState(false);
  const handleSeeAll = useCallback(() => {
    setExpandAccounts(true);
  }, []);
  const handleClose = useCallback(() => {
    setExpandAccounts(false);
  }, []);

  if (!expandAccounts) {
    const renderedAccounts =
      accounts.length > MAX_NUM_AVATAR
        ? accounts.slice(0, MAX_NUM_AVATAR)
        : accounts;

    return (
      <ClosedContainer>
        <ul>
          {renderedAccounts.map((account) => (
            <li key={account.rid}>
              <ImageBox account={account} isExpanded={expandAccounts} />
              <UserExternalLinkComponent
                clusterId={clusterId}
                conversationId={conversationId}
                screen_name={account.screen_name}
                url={account.profile_url}
                user_rid={account.rid}
              />
            </li>
          ))}
        </ul>
        <div>
          <CircleDiv>
            <span>{accounts.length}</span>
          </CircleDiv>
          <Button
            data-kind={ButtonKind.Tertiary}
            data-size={ButtonSize.sm}
            onPress={handleSeeAll}
            type="button"
          >
            See All
          </Button>
        </div>
      </ClosedContainer>
    );
  }

  return (
    <ExpandedContainer>
      <div>
        <StyledHeading>{`${accounts.length} Accounts`}</StyledHeading>
        <Button
          data-kind={ButtonKind.Tertiary}
          data-size={ButtonSize.sm}
          onPress={handleClose}
          type="button"
        >
          Close
        </Button>
      </div>
      <ul>
        {accounts.map((account) => (
          <li key={account.rid}>
            <ImageBox account={account} isExpanded={expandAccounts} />
            <UserExternalLinkComponent
              clusterId={clusterId}
              conversationId={conversationId}
              screen_name={account.screen_name}
              url={account.profile_url}
              user_rid={account.rid}
            />
          </li>
        ))}
      </ul>
    </ExpandedContainer>
  );
};

const useClusterPopUps = (clusterId: string, conversationId: string) => {
  const filters = useConversationFilters();
  const { data, isLoading, error } = useClusterProfile(
    clusterId,
    conversationId,
    filters,
  );

  const evidenceMatches = useMemo(() => {
    const matches = data?.data?.coordinations.reduce(
      (map, evidence) => {
        map[evidence as Evidence] = EVIDENCES.includes(evidence as Evidence);
        return map;
      },
      {} as Record<Evidence, boolean>,
    );

    return (
      matches ??
      EVIDENCES.reduce(
        (map, evidence) => ({ ...map, [evidence]: false }),
        {} as Record<Evidence, boolean>,
      )
    );
  }, [data?.data?.coordinations]);

  return {
    clusterData: data?.data,
    error,
    evidenceMatches,
    isLoading,
  };
};

export const ClusterPopUp: FunctionComponent<ClusterCoordination> = (props) => {
  const { clusterId, conversationId, onDismiss } = props;

  const { clusterData, error, evidenceMatches, isLoading } = useClusterPopUps(
    clusterId,
    conversationId,
  );

  return (
    <PopUpBase heading="Coordination Cluster" onDismiss={onDismiss}>
      {isLoading ? (
        <LoadingIndicator />
      ) : error ? (
        <ErrorContainer>
          An error occurred while loading cluster popup data
        </ErrorContainer>
      ) : clusterData && clusterData.coordinations.length ? (
        <>
          <ClusterAccounts
            clusterId={clusterId}
            conversationId={conversationId}
            data={clusterData}
          />
          <Evidences evidenceMatches={evidenceMatches} />
        </>
      ) : (
        <NoDataContainer>No Coordination Detected</NoDataContainer>
      )}
    </PopUpBase>
  );
};
