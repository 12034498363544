import { styled } from "@linaria/react";
import { type FunctionComponent, useMemo } from "react";

import { type DomainDetailsResponseDTO } from "~/dto/domainDetailsResponse";
import { type DomainRegistrationDTO } from "~/dto/domainRegistration";
import { useSharedURLS } from "~/hooks/useCoUrl";
import {
  useURLNodeUsersData,
  useDomainDetailsData,
} from "~/hooks/useURLNodePopup";
import { text } from "~/styles/typography";
import { dateCompare, formatDateAGStandard } from "~/utils/datetime";
import { getPrimaryDomainName } from "~/utils/domainUtils";

import Accordion from "./Accordion";
import AccountAvatar from "./AccountAvatar";
import Card from "./Card";
import { UserExternalLinkComponent } from "./ConnectionPreview";
import { DialogXButton, DialogContents } from "./Dialog";
import { DialogTable } from "./DialogTable";
import Field from "./Field";
import { DialogHeadingContainer, DialogTitle } from "./ModalDialog";
import Section, { Heading } from "./Section";
import { SkipRow, type TableColumn } from "./Table";
import { StyledDialogTable } from "./UserNodePopUp";
import { DomainInsights } from "./insights/InsightsPanel";
import { ExternalLink } from "./library/ExternalLink";
import LoadingIndicator from "./library/LoadingIndicator/LoadingIndicator";
import { Truncated, TruncatedWithoutPopover } from "./text";

const NO_DATA_MESSAGE = "N/A";

const HeadingText = styled(Heading)`
  ${text.sm.regular};
  line-height: 1;
`;

const Container = styled(Card)`
  max-width: 360px;
  max-height: 700px;
  display: flex;
  flex-direction: column;
  outline: none;

  ${DialogHeadingContainer} {
    justify-content: space-between;
    background-color: var(--background-color-brand-section);
    cursor: grab;
  }

  ${DialogContents} {
    background-color: var(--background-color-profile);
    overflow-y: hidden;

    section {
      padding: var(--spacing-lg);
      background-color: var(--background-color-primary);
    }

    > * {
      border: 1px solid var(--border-color-primary);
      border-radius: var(--border-radius-xl);
      overflow: hidden;
    }

    section:last-child {
      padding: 0;
    }

    &[aria-busy="true"] {
      padding-top: var(--gutter-800);
    }
  }
`;

const URLOverviewBox = styled(Section)`
  background-color: var(--background-color-primary);
  border-radius: var(--border-radius-dialog);
  padding: var(--spacing-lg);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
`;

const CurrentURLBox = styled.div`
  ${text.xs.regular};
  word-wrap: break-word;
  a {
    color: var(--color-link);
  }
`;

const OverviewBox = styled.div`
  display: flex;
  justify-content: space-between;

  > div {
    flex: auto;
  }
`;

const AccountBox = styled(Section)`
  background-color: var(--background-color-primary);
  border-radius: var(--border-radius-dialog);
  min-height: var(--gutter-800);
  overflow-y: auto;
`;

const StyledField = styled(Field)`
  align-items: center;
  word-break: break-word;

  > dt {
    color: var(--color-profile-field-label);
    font-size: var(--font-size-700);
    font-weight: var(--font-weight-profile-field-label);
    margin-bottom: 0;
  }

  > dd,
  span {
    color: var(--color-profile-field-value);
    font-size: var(--font-size-700);
  }
`;

const StyledAvatar = styled.span`
  width: 32px;
  grid-column: 1;
  grid-row: 1 / span 2;
  margin-right: var(--spacing-md);
  padding: 0;
  > img {
    width: 32px;
    height: 32px;
    border-radius: var(--border-radius-default);
    margin: 0;
    padding: 0;
  }
`;

const UserInfoContainer = styled.div`
  display: grid;
  grid-column: 2;
`;

const UserScreenNameField = styled(TruncatedWithoutPopover)`
  ${text.xs.regular};
  grid-row: 1;
  width: 100px;
  line-height: var(--spacing-xl);
`;

const UserNameField = styled(Truncated)`
  font-weight: var(--font-weight-medium);
  ${text.xs.regular};
  grid-row: 2;
  width: 100px;
  line-height: var(--spacing-xl);
`;

const PostDateField = styled.div`
  margin-top: var(--spacing-lg);
  font-weight: var(--font-weight-regular);
`;

const UserNameTD = styled.div`
  display: grid;
  grid-template-columns: min-content max-content;
`;

const URLTable = styled(StyledDialogTable)`
  thead tr {
    font-weight: var(--font-weight-regular);
    padding: 0 var(--spacing-lg) var(--spacing-lg);

    th:first-child > button {
      justify-content: flex-start;
    }

    button {
      ${text.xs.regular};
    }
  }

  ${SkipRow} {
    padding: 0;
  }

  tbody tr {
    background-color: none;
    padding: var(--spacing-md) 0;

    td {
      display: grid;
      border: none;
      word-wrap: break-word;
      white-space: normal;
      ${text.xs.regular};
      padding: 0;
    }

    td:first-child {
      padding-left: var(--spacing-lg);
    }

    td:last-child {
      padding-right: var(--spacing-lg);
    }
  }
`;

const PastDomainTable = styled(DialogTable)`
  display: table-header-group;
  text-align: center;

  thead tr {
    grid-template-columns: repeat(4, 1fr);
    padding: var(--spacing-lg) var(--spacing-lg) var(--spacing-xs)
      var(--spacing-lg);

    th button {
      color: var(--color-gray-500);
      font-size: var(--font-size-700);
      padding: 0;
    }
  }

  td {
    width: 70px;
  }

  th {
    padding: 0;
  }

  tbody {
    font-size: var(--font-size-700);
    overflow: hidden;

    tr {
      background-color: transparent;
      border-top: none;
      grid-template-columns: repeat(4, 1fr);
      padding: 0 var(--spacing-lg);
      text-align: left;

      td {
        padding: var(--spacing-xs) 0;
      }
    }
  }
`;

const PastDomainContainer = styled.div`
  div > div:nth-child(2) {
    max-height: 75px;
    overflow-y: auto;
  }

  div[data-expanded="true"] > div {
    padding: 0;
  }
`;

const PastDomainTableContainer = styled.div`
  overflow: hidden;
  max-height: 200px;
`;

const CurrentDomainRegistrationContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;

  & > :nth-child(-n + 3) {
    /* first three children split a row */
    max-width: 33%;
  }
  & > :nth-last-child(-n + 2) {
    /* last two children split a row */
    max-width: 50%;
  }

  ${StyledField}[disabled] {
    min-width: 0;
    display: block;
    overflow: hidden;
    text-align: center;

    dd {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 100%;
      font-weight: var(--font-weight-regular);
    }
  }
`;

const UnavailableField = styled.div`
  ${text.sm.regular};
  color: var(--text-color-tertiary);
`;

export interface URLNodePopUpProps {
  id: string;
  conversationId?: string;
  narrativeTopic?: string;
  onDismiss: () => void;
}

interface UserTableData {
  avatar_url?: string;
  screen_name?: string;
  display_name?: string;
  post_date?: string;
  profile_url?: string;
  rid?: string;
}

interface PastDomainTableData {
  name: string;
  phone: string;
  registrar: string;
  email: string;
}

const hasInfo = (data: string | undefined | null) => {
  if (data === "N/A" || data === "n/a" || data === "na" || !data) {
    return false;
  }

  return true;
};

const sortDomainsByExpiration = (
  a: DomainRegistrationDTO,
  b: DomainRegistrationDTO,
) => dateCompare(a.reg_expires_at ?? "", b.reg_expires_at ?? "");

const getCurrentDomainInfo = (domainData?: DomainDetailsResponseDTO) => {
  return domainData?.data?.registration?.sort(sortDomainsByExpiration).at(-1);
};

const getPastDomainInfo = (
  domainData: DomainDetailsResponseDTO | undefined,
) => {
  if (domainData?.data?.registration?.length === 0) {
    return [
      {
        name: NO_DATA_MESSAGE,
        phone: NO_DATA_MESSAGE,
        registrar: NO_DATA_MESSAGE,
        email: NO_DATA_MESSAGE,
      },
    ];
  }

  const _filterUniqueName = (
    value: DomainRegistrationDTO,
    index: number,
    self: DomainRegistrationDTO[],
  ) => {
    return self.findIndex((v) => v.name === value.name) === index;
  };

  const pastDomainInfo = domainData?.data?.registration
    ?.filter(_filterUniqueName)
    .sort(sortDomainsByExpiration)
    .reverse();

  return pastDomainInfo?.map((e) => ({
    name: e.name ?? NO_DATA_MESSAGE,
    phone: e.telephone ?? NO_DATA_MESSAGE,
    registrar: e.registrar ?? NO_DATA_MESSAGE,
    email: e.contact_email ?? NO_DATA_MESSAGE,
  }));
};

const formatPossiblyUnavailableDate = (dateString?: string) =>
  dateString ? formatDateAGStandard(new Date(dateString)) : NO_DATA_MESSAGE;

const formatSeenAtDate = (date?: Date) =>
  date ? formatDateAGStandard(date) : "";

const nameSortFunction = (a: UserTableData, b: UserTableData) => {
  if (
    (!a.display_name && !b.display_name) ||
    a.display_name === b.display_name
  ) {
    return 0;
  } else if (!a.display_name) {
    return -1;
  } else if (!b.display_name) {
    return 1;
  }

  return a.display_name.localeCompare(b.display_name);
};

const dateSortFunction = (a: UserTableData, b: UserTableData) => {
  if (!a.post_date && !b.post_date) {
    return 0;
  } else if (!a.post_date) {
    return -1;
  } else if (!b.post_date) {
    return 1;
  }

  return dateCompare(a.post_date, b.post_date);
};

const NoDataField = () => {
  return (
    <UnavailableField title="Unavailable">{NO_DATA_MESSAGE}</UnavailableField>
  );
};

const pastDomainColumnData: TableColumn<PastDomainTableData>[] = [
  {
    field: "name",
    label: "Names",
    filterable: false,
    renderFn: ({ data }) =>
      hasInfo(data.name) ? <Truncated>{data.name}</Truncated> : <NoDataField />,
  },
  {
    field: "phone",
    label: "Phones",
    filterable: false,
    renderFn: ({ data }) =>
      hasInfo(data.phone) ? (
        <Truncated>{data.phone}</Truncated>
      ) : (
        <NoDataField />
      ),
  },
  {
    field: "registrar",
    label: "Registrars",
    filterable: false,
    renderFn: ({ data }) =>
      hasInfo(data.registrar) ? (
        <Truncated>{data.registrar}</Truncated>
      ) : (
        <NoDataField />
      ),
  },
  {
    field: "email",
    label: "Emails",
    filterable: false,
    renderFn: ({ data }) =>
      hasInfo(data.email) ? (
        <Truncated>{data.email}</Truncated>
      ) : (
        <NoDataField />
      ),
  },
];

const getUserColumnData: (
  conversationId: string,
) => TableColumn<UserTableData>[] = (conversationId: string) => [
  {
    field: "name",
    label: "Name",
    filterable: false,
    sortFn: nameSortFunction,
    renderFn: ({ data }) => (
      <UserNameTD>
        <StyledAvatar>
          <AccountAvatar url={data.avatar_url} />
        </StyledAvatar>
        <UserInfoContainer>
          <UserScreenNameField>
            {data.screen_name && data.rid && (
              <UserExternalLinkComponent
                conversationId={conversationId}
                screen_name={data.screen_name}
                url={data.profile_url}
                user_rid={data.rid}
              />
            )}
          </UserScreenNameField>
          <UserNameField>{data.display_name ?? ""}</UserNameField>
        </UserInfoContainer>
      </UserNameTD>
    ),
  },
  {
    field: "date",
    label: "Date Shared",
    filterable: false,
    sortFn: dateSortFunction,
    renderFn: ({ data }) => (
      <PostDateField>
        {data.post_date ? formatDateAGStandard(new Date(data.post_date)) : ""}
      </PostDateField>
    ),
  },
];

const URLNodePopUp: FunctionComponent<URLNodePopUpProps> = (props) => {
  const { id, conversationId, onDismiss } = props;
  const { data = { data: [], metadata: {} }, isLoading: isLoadingUsersData } =
    useURLNodeUsersData(conversationId, id);
  const { data: sharedURLCount } = useSharedURLS(conversationId, { url: id });
  const urlCount = sharedURLCount?.data[0]?.value ?? data.data.length;
  const showOverviewData = data.data.length > 0;
  const postDates = data?.data
    ?.filter((user) => user.post_date !== undefined)
    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        -- we're filtering out the bad ones already */
    .map((user) => new Date(user.post_date!));
  const primaryDomainName = getPrimaryDomainName(id);
  const { data: domainData, isLoading } = useDomainDetailsData(
    primaryDomainName,
    conversationId,
    false,
    10,
  );
  const firstSeen = formatSeenAtDate(postDates?.at(0));
  const lastSeen = formatSeenAtDate(postDates?.at(-1));
  const userColumnData = useMemo(() => {
    return conversationId ? getUserColumnData(conversationId) : [];
  }, [conversationId]);
  const userRowData = data?.data;
  const pastDomainRowData = getPastDomainInfo(domainData);
  const currentRegistration = getCurrentDomainInfo(domainData);
  const url = id.includes("://") ? id : `//${id}`;

  return (
    <Container aria-label="URL Profile">
      <DialogHeadingContainer className="dialog-heading">
        <DialogTitle>
          <Truncated text={primaryDomainName}>
            <span>{primaryDomainName}</span>
          </Truncated>
        </DialogTitle>
        <DialogXButton onPress={onDismiss} />
      </DialogHeadingContainer>
      <DialogContents aria-busy={isLoadingUsersData}>
        {isLoadingUsersData ? (
          <LoadingIndicator />
        ) : (
          <>
            <URLOverviewBox>
              <CurrentURLBox aria-label="URL">
                <Truncated href={id} text={id}>
                  <ExternalLink href={url}>{id}</ExternalLink>
                </Truncated>
              </CurrentURLBox>
              {showOverviewData && (
                <OverviewBox aria-label="URL Overview">
                  <Field name="Posts" value={urlCount} />
                  <Field name="Accounts" value={data?.data.length} />
                  {firstSeen && (
                    <Field
                      name="First Seen"
                      title="First Time Seen in Conversation"
                      value={firstSeen}
                    />
                  )}
                  {lastSeen && (
                    <Field
                      name="Last Seen"
                      title="Last Time Seen in Conversation"
                      value={lastSeen}
                    />
                  )}
                </OverviewBox>
              )}
            </URLOverviewBox>
            <DomainInsights domain={id} />
            {userRowData && (
              <>
                <Accordion title="Current Registration">
                  {isLoading ? (
                    <LoadingIndicator />
                  ) : (
                    <CurrentDomainRegistrationContainer>
                      <Field
                        name="Creation"
                        title={
                          currentRegistration?.reg_created_at
                            ? undefined
                            : "Unavailable"
                        }
                        value={formatPossiblyUnavailableDate(
                          currentRegistration?.reg_created_at,
                        )}
                      />
                      <Field
                        name="Expiration"
                        title={
                          currentRegistration?.reg_expires_at
                            ? undefined
                            : "Unavailable"
                        }
                        value={formatPossiblyUnavailableDate(
                          currentRegistration?.reg_expires_at,
                        )}
                      />
                      <Field
                        name="Last Updated"
                        title={
                          currentRegistration?.reg_updated_at
                            ? undefined
                            : "Unavailable"
                        }
                        value={formatPossiblyUnavailableDate(
                          currentRegistration?.reg_updated_at,
                        )}
                      />

                      <Field name="Registrar" value={""}>
                        {hasInfo(currentRegistration?.registrar) ? (
                          <Truncated>
                            {currentRegistration?.registrar as string}
                          </Truncated>
                        ) : (
                          <NoDataField />
                        )}
                      </Field>

                      <Field name="Registrar Email" value={""}>
                        {hasInfo(currentRegistration?.contact_email) ? (
                          <Truncated>
                            {currentRegistration?.contact_email as string}
                          </Truncated>
                        ) : (
                          <NoDataField />
                        )}
                      </Field>
                    </CurrentDomainRegistrationContainer>
                  )}
                </Accordion>

                <PastDomainContainer>
                  <Accordion title="Past Registration">
                    {isLoading ? (
                      <LoadingIndicator />
                    ) : (
                      <PastDomainTableContainer>
                        {pastDomainRowData && (
                          <PastDomainTable
                            disableFilter
                            id="domain-profile-past-domains-table"
                            tableColumns={pastDomainColumnData}
                            tableData={pastDomainRowData}
                          />
                        )}
                      </PastDomainTableContainer>
                    )}
                  </Accordion>
                </PastDomainContainer>

                {showOverviewData && (
                  <AccountBox aria-labelledby="domain-profile-accounts-header">
                    <HeadingText id="domain-profile-accounts-header">
                      Accounts
                    </HeadingText>
                    {userRowData && (
                      <URLTable
                        aria-labelledby="domain-profile-accounts-header"
                        disableFilter
                        id="domain-profile-accounts-table"
                        initialSort={{ id: "date", desc: true }}
                        tableColumns={userColumnData}
                        tableData={userRowData}
                      />
                    )}
                  </AccountBox>
                )}
              </>
            )}
          </>
        )}
      </DialogContents>
    </Container>
  );
};

export default URLNodePopUp;
