import type { ReactNode } from "react";

import type { RawDetailsResponseDTO } from "~/dto";
import type { GenericDataShape, GenericNode } from "~/utils/visualizationTypes";

export type GraphDataShape = GenericDataShape<
  QueryNode,
  EdgeWithEdgeType,
  NodeType
>;
export type GraphNode = GraphDataShape["nodes"][0];
export type GraphEdge = GraphDataShape["edges"][0];
export type GraphCombo = GraphDataShape["combos"][0];

export function isGraphTopicNode(
  x: GraphNode,
): x is GenericNode<QueryTopic, "topics"> {
  return x.data.nodeType === "topics";
}

interface CoordinationUserNodeData extends QueryAccount {
  coordinationClusterId: string;
}

export function isPostNodeData(
  x: GraphNode["data"],
): x is GenericNode<QueryPost, NodeType>["data"] {
  return x.nodeType === "posts";
}
export function isAccountNodeData(
  x: GraphNode["data"],
): x is GenericNode<QueryAccount, "accounts">["data"] {
  return x.nodeType === "accounts";
}
export function isDomainNodeData(
  x: GraphNode["data"],
): x is GenericNode<QueryDomain, "domains">["data"] {
  return x.nodeType === "domains";
}
export function isURLNodeData(
  x: GraphNode["data"],
): x is GenericNode<QueryURLs, "links">["data"] {
  return x.nodeType === "links";
}
export function isHashtagNodeData(
  x: GraphNode["data"],
): x is GenericNode<QueryHashtag, "hashtags">["data"] {
  return x.nodeType === "hashtags";
}
export function isConversationClusterNodeData(
  x: GraphNode["data"],
): x is GenericNode<QueryTopic, "topics">["data"] {
  return x.nodeType === "topics";
}

export function isCoordinationUserNodeData(
  x: GraphNode["data"],
): x is GenericNode<CoordinationUserNodeData, NodeType>["data"] {
  return x.type === "accounts" && x.coordinationClusterId !== undefined;
}

/**
 * Represents the core data associated with an edge in the graph.
 * Includes a unique identifier and can hold various attributes.
 */
export interface EdgeData extends Record<string, unknown> {
  id: string;
  source: string;
  target: string;
}

/** maps of node types to available edges */
export const availableEdges = {
  accounts: [
    "accountPosts",
    "accountRepostedPosts",
    "accountQuotedPosts",
    "accountRepliedPosts",
    "accountCoordinationEvidenceAccounts",
    "accountLinks",
    "accountRepostedLinks",
    "accountHashtags",
    "accountRepostedHashtags",
    "accountDomains",
    "accountRepostedDomains",
    "accountRepostedAccounts",
    "accountQuotedAccounts",
    "accountRepliedAccounts",
  ],
  domains: [
    "domainPosts",
    "postRepostedDomains",
    "accountDomains",
    "accountRepostedDomains",
    "domainLinks",
  ],
  links: [
    "linkPosts",
    "accountLinks",
    "accountRepostedLinks",
    "postRepostedLinks",
    "domainLinks",
  ],
  posts: [
    "accountPosts",
    "accountRepostedPosts",
    "accountQuotedPosts",
    "accountRepliedPosts",
    "domainPosts",
    "postRepostedDomains",
    "linkPosts",
    "postRepostedLinks",
    "postHashtags",
    "postRepostedPosts",
    "postQuotedPosts",
    "postRepliedPosts",
    "postRepostedHashtags",
  ],
  hashtags: [
    "postHashtags",
    "accountHashtags",
    "accountRepostedHashtags",
    "postRepostedHashtags",
  ],
  topics: [],
} as const;

export type NodeType = keyof typeof availableEdges;
export const nodeTypes = Object.keys(availableEdges) as NodeType[];
export function isNodeType(x: unknown): x is NodeType {
  return typeof x === "string" && x in availableEdges;
}

export type AvailableEdges<T extends keyof typeof availableEdges = NodeType> =
  (typeof availableEdges)[T][number];
export type AllAvailableEdges = AvailableEdges;
const allEdgesSet = new Set<string>(
  Object.values(availableEdges).flatMap((edges) => edges),
);
export function isEdge(x: unknown): x is AllAvailableEdges {
  return x !== null && typeof x === "string" && allEdgesSet.has(x);
}

export type Preset = "narrative" | "network" | "coordination" | "custom";
export function isPreset(value: string): value is Preset {
  return ["narrative", "network", "coordination", "custom"].includes(value);
}

export type Edges =
  | AccountPosts
  | DomainPosts
  | LinkPosts
  | AccountCoordinationEvidenceAccounts
  | QueryHashtag
  | AccountLinks
  | AccountHashtags
  | AccountDomains
  | AccountToAccount
  | PostToPost
  | DomainLinks;
/*  Type for incoming Data from GraphQL  */

export type EdgeWithEdgeType = Edges & { edgeType: AllAvailableEdges };

/**
 * Defines the shape of node returned from a graph query.
 * These nodes can contain various types of data related to
 * posts, accounts, domains, links, and hashtags.
 */
export interface QueryNodeShape {
  posts?: QueryPost[];
  accounts?: QueryAccount[];
  domains?: QueryDomain[];
  links?: QueryURLs[];
  hashtags?: QueryHashtag[];
  topics?: QueryTopic[];
}

/**
 * Defines the shape of an edge returned from a graph query.
 * Edges represent the connections between nodes and hold information about the relationship between them.
 */ export interface QueryEdgeShape {
  accountPosts?: AccountPosts[];
  domainPosts?: DomainPosts[];
  linkPosts?: LinkPosts[];
  accountCoordinationEvidenceAccounts?: AccountCoordinationEvidenceAccounts[];
  postHashtags?: QueryHashtag[];
  postRepostedHashtags?: QueryHashtag[];
  accountLinks?: AccountLinks[];
  accountHashtags?: AccountHashtags[];
  accountRepostedHashtags?: AccountHashtags[];
  accountQuotedPosts?: AccountPosts[];
  accountRepliedPosts?: AccountPosts[];
  accountRepostedPosts?: AccountPosts[];
  accountDomains?: AccountDomains[];
  accountRepostedDomains?: AccountDomains[];
  accountRepostedLinks?: AccountLinks[];
  postRepostedDomains?: DomainPosts[];
  postRepostedLinks?: LinkPosts[];
  accountRepliedAccounts?: AccountToAccount[];
  accountQuotedAccounts?: AccountToAccount[];
  accountRepostedAccounts?: AccountToAccount[];
  domainLinks?: DomainLinks[];
  postQuotedPosts?: PostToPost[];
  postRepliedPosts?: PostToPost[];
  postRepostedPosts?: PostToPost[];
}
/**
 * Defines the shape of a cluster returned from a graph query.
 * Clusters represent groupings of nodes based on shared characteristics or relationships.
 */
interface QueryClusterShape {
  topics?: Topics[];
}

export type QueryShape = QueryNodeShape & QueryEdgeShape & QueryClusterShape;

const clusteringNodeKeys = [
  "coordinationClusterId",
  "platform",
  "topicId",
] as const;

export type ClusteringNodeKey = (typeof clusteringNodeKeys)[number];
type ExtraNodeKey = "body" | "url" | "domain" | "type";

type QueryBase = {
  id: string;
} & {
  [K in ClusteringNodeKey]?: string | null;
} & {
  [K in ExtraNodeKey]?: string;
};
export interface QueryAccount extends QueryBase {
  id: string;
  displayName: string;
  platform: string;
  screenName: string;
  sourceDomain: string;
}

export type QueryNode =
  | QueryPost
  | QueryURLs
  | QueryDomain
  | QueryHashtag
  | QueryAccount
  | QueryTopic;

export interface QueryPost extends QueryBase {
  accountId: string;
  additionalProperties: RawDetailsResponseDTO["data"]["additional_properties"];
  id: string;
  body: string;
  publishedAt: string;
  sourceDomain: string;
  topicId: string;
  type: string;
}

export interface QueryURLs extends QueryBase {
  id: string;
  url: string;
  // the bottom keys only appear for URL Sharing
  postedCount?: number;
  accountEdges?: QueryAccount[];
}

export interface QueryDomain extends QueryBase {
  id: string;
  name: string;
}

export interface QueryHashtag extends QueryBase {
  postId: string;
  text: string;
}

export type QueryTopic = QueryBase & Topics;

/* edge types */

export interface AccountPosts {
  accountId: string;
  postId: string;
}

export interface DomainPosts {
  domainId: string;
  postId: string;
}

export interface AccountDomains {
  domainId: string;
  accountId: string;
}

export interface LinkPosts {
  linkId: string;
  postId: string;
}

export interface AccountCoordinationEvidenceAccounts {
  fromAccountId: string;
  toAccountId: string;
  weight: number;
}

export interface AccountHashtags {
  accountId: string;
  text: string;
}

export interface AccountLinks {
  accountId: string;
  linkId: string;
}

export interface PostToPost {
  fromPostId: string;
  toPostId: string;
}

export interface AccountToAccount {
  fromAccountId: string;
  toAccountId: string;
}

export interface DomainLinks {
  linkId: string;
  domainId: string;
}

/* cluster data */
export interface Topics {
  id: string;
  ngrams: string[];
  postCount: number;
  title: string | null;
}

// TODO: these typedefs are driven by the first use-case (of setting url query
// params); ideally we'd refactor so it would be the other way around
export type GraphStateEntry<T> = [
  T[],
  (selection: T | T[] | "" | ((prev: T[]) => T[])) => void,
];
export type GraphState = {
  cluster: GraphStateEntry<ClusteringNodeKey>;
  colorGroupBy: GraphStateEntry<string>;
  edge: GraphStateEntry<AllAvailableEdges>;
  node: GraphStateEntry<NodeType>;
  preset: GraphStateEntry<Preset>;
  numOfMinEdge: GraphStateEntry<string>;
  showCoordinatedPosts: GraphStateEntry<string>;
};
export type GraphContextProviderProps = {
  children: ReactNode;
  value: {
    conversationId: string;
    graphState: GraphState;
  };
};

export function isAccountCoordinationEvidenceAccounts(
  x: Edges,
): x is AccountCoordinationEvidenceAccounts {
  return "fromAccountId" in x && "toAccountId" in x && "weight" in x;
}

export function isAccountLinks(x: Edges): x is AccountLinks {
  return "accountId" in x && "linkId" in x;
}

export function isClusteringNodeKey(x: unknown): x is ClusteringNodeKey {
  return typeof x === "string" && clusteringNodeKeys.some((el) => el === x);
}

export interface URLSharingData {
  links: {
    id: string;
    url: string;
    postedCount: number;
    accountEdges: QueryAccount[];
  }[];
}

export type LegendItem = {
  main: string;
  text: string;
  id: string;
  label: string;
};

export interface ColorMap {
  coordination: Map<string, LegendItem>;
  narratives: Map<string, LegendItem>;
  nodes: Map<NodeType, LegendItem>;
}

export interface TimeData {
  posts?: {
    id: string;
    publishedAt: string;
  }[];
}

export interface ProcessedData {
  processedEdges: GraphDataShape["edges"][];
  nodesWithMultipleConnections: GraphDataShape["nodes"][];
  nodesWithSingleConnection: GraphDataShape["nodes"][];
  nodesWithoutConnections: GraphDataShape["nodes"][];
}
