import {
  type ReactNode,
  type FunctionComponent,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from "react";
import { type NavigateOptions, useSearchParams } from "react-router-dom";

import type { MegaConversationFilterOptions } from "~/components/ConversationFilters/utils";
import { platforms } from "~/constants";
import {
  type ConversationDTO,
  type ConversationFilterSummaryDTO,
  type FilterCountsDTO,
  type InsightRiskDTO,
  type TopicDTO,
} from "~/dto";
import { FilterCountsValidator } from "~/dto/filterCounts";
import { typeNameTitleMap } from "~/pages/InsightsPage/constants";
import { pythonISOString } from "~/utils/datetime";

import { useCurrentConversation } from "./useConversation";
import { useConversationInsights } from "./useConversationInsights";
import { useConversationTopics } from "./useNarrative";
import { useRemoteObject } from "./useRemoteData";

export type MegaFilterKey = "insight_risk";
export function isMegaFilterKey(key: unknown): key is MegaFilterKey {
  return key === "insight_risk";
}

export const visibleFilterTypeForQueryKey = {
  coordination_cluster: "coordination_cluster",
  narrative: "narratives",
  platform: "platforms",
  domain: "domains",
  sentiment: "sentiments",
  contains_threatening_language: "contains_threatening_language",
  insight_subcategory: "subcategories",
  insight_country_of_origin: "countries_of_origin",
  insight_operating_country: "operating_countries",
  insight_state_association: "state_associations",
  insight_role: "roles",
  insight_language: "languages",
  insight_region: "regions",
} as const;

/* 
these are filters that are only activated through the mega filter
users cannot manually enable them and it is not visible in the app
*/
export const hiddenFilterTypeForQueryKey = {
  is_niche: "is_niche",
  engagement_score: "engagement_score",
} as const;

export const filterTypeForQueryKey = {
  ...visibleFilterTypeForQueryKey,
  ...hiddenFilterTypeForQueryKey,
} as const;

export type FilterKey = keyof typeof filterTypeForQueryKey;
export type CombinedFilterKey = FilterKey | MegaFilterKey;
export type VisibleFilterKey = keyof typeof visibleFilterTypeForQueryKey;
export function isVisibleFilterKey(key: string): key is VisibleFilterKey {
  return key in visibleFilterTypeForQueryKey;
}
export type HiddenFilterKey = keyof typeof hiddenFilterTypeForQueryKey;

type FilterType = (typeof filterTypeForQueryKey)[FilterKey];
type VisibleFilterType =
  (typeof visibleFilterTypeForQueryKey)[VisibleFilterKey];

const queryKeyForFilter = Object.fromEntries(
  Object.entries(filterTypeForQueryKey).map(([key, value]) => [value, key]),
) as Record<FilterType, FilterKey>;

export type FilterSelection = { [key: string]: unknown };

export type ConversationFilters = {
  [key in Exclude<
    FilterType,
    "contains_threatening_language" | "coordination_cluster" | "is_niche"
  >]: FilterSelection;
} & {
  coordination_cluster: boolean | null;
  contains_threatening_language: boolean | null;
  is_niche: boolean | null;
  start_range: Date;
  end_range: Date;
};

export type FilterOption = {
  id: string;
  label?: string;
  totalCount: number;
  filteredCount?: number;
};

export type ConversationFilterOptions = {
  [key in VisibleFilterType]: FilterOption[];
};

const DELIMITER = ":";

export function queryParamToSelection(param: string): [string, boolean] {
  if (
    !param.endsWith(`${DELIMITER}true`) &&
    !param.endsWith(`${DELIMITER}false`)
  ) {
    // handle old query strings that were implicitly included
    return [param, true];
  }

  const index = param.lastIndexOf(DELIMITER);
  const id = param.slice(0, index);
  const value = param.slice(index + 1);
  return [id, value === "true"];
}

export function queryParamsToSelection(params: string[]): {
  [key: string]: boolean;
} {
  return Object.fromEntries(params.map(queryParamToSelection));
}

export function selectionToQueryParam(
  selection: [keyof FilterSelection, FilterSelection[keyof FilterSelection]],
): string {
  return selection.join(DELIMITER);
}

export function selectionToQueryParams(selection: FilterSelection): string[] {
  return Object.entries(selection)
    .filter(([, value]) => typeof value === "boolean")
    .map(selectionToQueryParam);
}

export function isIncluded(selection: FilterSelection, id: string): boolean {
  return selection[id] === true;
}

export function isExcluded(selection: FilterSelection, id: string): boolean {
  return selection[id] === false;
}

export const emptyFilters: () => ConversationFilters = () => {
  return Object.values(filterTypeForQueryKey).reduce<ConversationFilters>(
    (filters, key) => {
      if (
        key === "contains_threatening_language" ||
        key === "coordination_cluster" ||
        key === "is_niche"
      ) {
        filters[key] = null;
      } else {
        filters[key] = {};
      }
      return filters;
    },
    {} as ConversationFilters,
  );
};

export function isFilterKey(key: string): key is FilterKey {
  return key in filterTypeForQueryKey;
}

type InsightFilterKey = Extract<FilterKey, `insight_${string}`>;

function isInsightsFilterKey(key: string): key is InsightFilterKey {
  return isFilterKey(key) && key.startsWith("insight_");
}

export function getConversationFiltersFromSearchParams(
  searchParams: URLSearchParams,
): ConversationFilters {
  return [...searchParams.entries()].reduce<ConversationFilters>(
    (filters, [key, value]) => {
      if (!isFilterKey(key)) {
        return filters;
      }

      if (
        key === "contains_threatening_language" ||
        key === "coordination_cluster" ||
        key === "is_niche"
      ) {
        filters[key] =
          value === "true" ? true : value === "false" ? false : null;
      } else {
        const selection = filters[filterTypeForQueryKey[key]];
        const [id, isIncluded] = queryParamToSelection(value);
        selection[id] = isIncluded;
      }

      return filters;
    },
    emptyFilters(),
  );
}

export const ConversationFiltersContext = createContext<ConversationFilters>(
  emptyFilters(),
);

interface ConversationFiltersProviderProps {
  conversation: ConversationDTO;
  children?: ReactNode;
}

export const ConversationFiltersProvider: FunctionComponent<
  ConversationFiltersProviderProps
> = (props) => {
  const [searchParams] = useSearchParams();

  const filters: ConversationFilters = useMemo(() => {
    const startRange =
      searchParams.get("start_range") ?? props.conversation.start_date;
    const endRange =
      searchParams.get("end_range") ?? props.conversation.end_date;

    return {
      ...getConversationFiltersFromSearchParams(searchParams),
      start_range: new Date(startRange),
      end_range: new Date(endRange),
    };
  }, [
    searchParams,
    props.conversation.start_date,
    props.conversation.end_date,
  ]);

  return (
    <ConversationFiltersContext.Provider value={filters}>
      {props.children}
    </ConversationFiltersContext.Provider>
  );
};

/**
 * Returns the current conversation filters from the URL search params.
 */
export function useConversationFilters(): ConversationFilters {
  return useContext(ConversationFiltersContext);
}

/**
 * Returns the initial conversation filters from the URL search params.
 * This function returns the filters as they were when the component first mounted.
 */
export function useInitialConversationFilters(): ConversationFilters {
  const filters = useConversationFilters();
  const ref = useRef(filters);
  return ref.current;
}

/**
 * Returns a URLSearchParams object containing only the conversation filter key/value pairs.
 * This is useful for generating a backend request URL.
 */
export function getConversationFilterRequestParams(
  filters?: ConversationFilters,
): URLSearchParams {
  if (!filters) {
    return new URLSearchParams();
  }

  const params = Object.entries(filters).reduce((params, [key, values]) => {
    if (values == null) {
      return params;
    }

    if (Array.isArray(values)) {
      values
        // Since we use the full URL as the SWR key, sort the values so the key is consistent.
        .toSorted()
        .forEach((value) => {
          params.append(queryKeyForFilter[key as FilterType], `${value}`);
        });
    } else if (values instanceof Date) {
      // serialize dates in format artemis KG expects
      params.append(key, pythonISOString(values));
    } else if (typeof values === "boolean") {
      params.append(key, String(values));
    } else if (typeof values === "object") {
      selectionToQueryParams(values)
        .toSorted()
        .forEach((param) => {
          params.append(queryKeyForFilter[key as FilterType], param);
        });
    } else {
      params.append(key, String(values));
    }
    return params;
  }, new URLSearchParams());

  // We sorted the values, now let URLSearchParams sort the keys.
  params.sort();

  return params;
}

const isEmpty = (selection: object): boolean =>
  Object.keys(selection).length === 0;

const isBooleanValueIncluded = (
  selection: boolean | null,
  value: boolean | null,
) => selection == null || selection === value;

// a single value matches if there are no filters or it is explicitly included
const isStringValueIncluded = (
  selection: FilterSelection,
  value: string,
): boolean => {
  // if there are no filters, everything is included
  if (isEmpty(selection)) {
    return true;
  }

  // if there are only exclusion filters, check if the value is excluded
  if (Object.values(selection).every((v) => v === false)) {
    return !isExcluded(selection, value);
  }

  // otherwise, there are inclusion filters, so check if the value is included
  return isIncluded(selection, value);
};

// an array of values matches if there are no filters or at least one value is included and none are excluded
const isArrayValueIncluded = (
  selection: FilterSelection,
  values: (string | null)[],
): boolean => {
  // if there are no filters, everything is included
  if (isEmpty(selection)) {
    return true;
  }

  // if there are only exclusion filters, check if any value is excluded
  if (Object.values(selection).every((v) => v === false)) {
    return values.every(
      (value) => value == null || !isExcluded(selection, value),
    );
  }

  // otherwise, there are inclusion filters, so make sure at least one value is included and none are excluded
  return (
    values.some((value) => value && isIncluded(selection, value)) &&
    values.every((value) => value == null || !isExcluded(selection, value))
  );
};

export function isSummaryIncluded(
  summary: ConversationFilterSummaryDTO,
  filters: ConversationFilters,
): boolean {
  const {
    narrative,
    platform,
    domain,
    coordination_cluster,
    sentiment,
    contains_threatening_language,
    is_niche,
    engagement_score,
    total,
    ...rest
  } = summary;

  const isNarrativeIncluded = isStringValueIncluded(
    filters.narratives,
    narrative,
  );
  const isPlatformIncluded = isStringValueIncluded(filters.platforms, platform);
  const isDomainIncluded = isArrayValueIncluded(filters.domains, domain);
  const isCoordinationIncluded = isBooleanValueIncluded(
    filters.coordination_cluster,
    coordination_cluster,
  );
  const isSentimentIncluded = isStringValueIncluded(
    filters.sentiments,
    sentiment,
  );
  const isThreateningLanguageIncluded = isBooleanValueIncluded(
    filters.contains_threatening_language,
    contains_threatening_language,
  );
  const areInsightsIncluded = Object.keys(rest)
    .filter(isInsightsFilterKey)
    .every((key) => {
      const filter = filters[filterTypeForQueryKey[key]];
      const traits = rest[key];
      return isArrayValueIncluded(filter, traits);
    });
  const isNichenessIncluded = isBooleanValueIncluded(
    filters.is_niche,
    is_niche ?? null,
  );
  const isEngagementScoreIncluded = isStringValueIncluded(
    filters.engagement_score,
    engagement_score ?? "",
  );

  return (
    isNarrativeIncluded &&
    isPlatformIncluded &&
    isDomainIncluded &&
    isCoordinationIncluded &&
    isSentimentIncluded &&
    isThreateningLanguageIncluded &&
    areInsightsIncluded &&
    isNichenessIncluded &&
    isEngagementScoreIncluded
  );
}

/**
 * The API response is essentially the result of grouping all raw-data records
 * by all filterable properties. so that for each object in the array, the
 * `total` property represents how many raw-data records match that exact
 * combination of conversation filters
 */
function useFilterCounts(
  filters: ConversationFilters,
  conversation: ConversationDTO | undefined,
) {
  const apiEndpoint = useMemo(() => {
    if (!conversation) {
      return;
    }

    // we only need to refresh the fetched data when the selected date range, so we can ignore the rest of the filters
    const { start_range, end_range } = filters;
    const params = getConversationFilterRequestParams({
      ...emptyFilters(),
      start_range,
      end_range,
    });
    return `/api/v2/conversations/${conversation.id}/filter_counts?${params}`;
  }, [conversation, filters]);

  return useRemoteObject<FilterCountsDTO>(apiEndpoint, {
    /* currently immutable */
    cacheOpts: {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
    schemaValidator: FilterCountsValidator,
  });
}

export function useConversationFilterOptions(
  conversationId: string,
): ConversationFilterOptions & {
  isLoading: boolean;
} & MegaConversationFilterOptions {
  const filters = useConversationFilters();
  const { topics, isLoading: isLoadingTopics } =
    useConversationTopics(conversationId);
  const { conversation } = useCurrentConversation();
  const {
    data: { data: filterSummaries = [] } = {},
    isLoading: isLoadingFilterCounts,
  } = useFilterCounts(filters, conversation);

  const { data: insightRisks, isLoading: isLoadingInsightRisks } =
    useConversationInsights(conversationId);

  /* for the denominator (i.e. the total count), we need to use the unfiltered
  counts, which are the counts for the same date range but without any other
  filters applied. */
  const unfilteredCounts = useMemo(() => {
    const baseFilters: ConversationFilters = {
      ...emptyFilters(),
      start_range: filters.start_range,
      end_range: filters.end_range,
    };
    return getFilterCounts(filterSummaries, baseFilters);
  }, [filters.start_range, filters.end_range, filterSummaries]);

  const filterOptions: ConversationFilterOptions = useMemo(() => {
    const {
      narratives: filteredNarratives,
      platforms: filteredPlatforms,
      coordination_cluster: filteredCoordinationCluster,
      contains_threatening_language: filteredThreateningLanguage,
      sentiments: filteredSentiments,
      ...filteredCounts
    } = getFilterCounts(filterSummaries, filters);

    type FilteredCounts = typeof filteredCounts;

    return {
      narratives: topics.map((topic) => ({
        id: topic.rid,
        label: getPrimaryTopic(topic),
        totalCount: unfilteredCounts.narratives[topic.rid] ?? 0,
        filteredCount: filteredNarratives[topic.rid] ?? 0,
      })),
      platforms: Object.keys(filteredPlatforms)
        .filter(isPlatform)
        .map((p) => ({
          id: p,
          label: platforms[p],
          totalCount: unfilteredCounts.platforms[p] ?? 0,
          filteredCount: filteredPlatforms[p],
        })),
      coordination_cluster: getYesNoOptions(
        filteredCoordinationCluster,
        unfilteredCounts.coordination_cluster,
      ),
      contains_threatening_language: getYesNoOptions(
        filteredThreateningLanguage,
        unfilteredCounts.contains_threatening_language,
      ),
      sentiments: Object.keys(filteredSentiments).map((id) => ({
        id,
        label: getSentimentLabelForId(id),
        totalCount: unfilteredCounts.sentiments[id] ?? 0,
        filteredCount: filteredSentiments[id],
      })),
      /* Object.keys() erases the type of the keys because the object could be
      mutated at runtime, but in this case we know the keys are fixed, so we can
      cast them back to the original type. */
      ...(Object.keys(filteredCounts) as (keyof FilteredCounts)[]).reduce(
        (counts, key) => {
          const unfiltered = unfilteredCounts[key];
          const filtered = filteredCounts[key];

          counts[key] = Object.entries(filtered).map(([id, filteredCount]) => ({
            id,
            filteredCount,
            totalCount: unfiltered[id] ?? 0,
          }));

          return counts;
        },
        {} as Pick<ConversationFilterOptions, keyof FilteredCounts>,
      ),
    };
  }, [filters, filterSummaries, topics, unfilteredCounts]);

  const megaFilterOptions: MegaConversationFilterOptions = useMemo(() => {
    const baseFilters: ConversationFilters = {
      ...emptyFilters(),
      start_range: filters.start_range,
      end_range: filters.end_range,
    };

    const filteredInsightRisks = insightRisks.reduce<InsightRiskDTO[]>(
      (acc, risk) => {
        const { filters, ...rest } = risk;
        const modifiedRisk = {
          ...rest,
          filters: filterMegaFilters(filters, filterOptions),
        };
        acc.push(modifiedRisk);
        return acc;
      },
      [],
    );

    const baseCounts = getCountsForMegaFilters(
      filterSummaries,
      baseFilters,
      filteredInsightRisks,
    );
    const filteredCounts = getCountsForMegaFilters(
      filterSummaries,
      filters,
      filteredInsightRisks,
    );

    return {
      insight_risk: filteredInsightRisks.map((r) => ({
        id: r.type_name,
        filters: r.filters,
        label: typeNameTitleMap.en?.[r.type_name],
        totalCount: baseCounts?.[r.type_name] ?? 0,
        filteredCount: filteredCounts?.[r.type_name] ?? 0,
      })),
    };
  }, [filterOptions, filterSummaries, filters, insightRisks]);

  const isLoading =
    isLoadingTopics || isLoadingFilterCounts || isLoadingInsightRisks;

  return {
    isLoading,
    ...filterOptions,
    ...megaFilterOptions,
  };
}

function getSentimentLabelForId(id: string) {
  switch (id) {
    case "POSITIVE":
      return "Positive";
    case "NEGATIVE":
      return "Negative";
    case "NEUTRAL":
      return "Neutral";
    default:
      return "Unknown";
  }
}

type FilterCounts = ReturnType<typeof getCountsForFilter>;

function getYesNoOptions(filtered: FilterCounts, unfiltered: FilterCounts) {
  // if every value is null (or any other kind of "unknown"), show the empty state
  if (!("true" in filtered) && !("false" in filtered)) {
    return [];
  }

  // otherwise, be sure to include both "yes" and "no" in the options, even if they are not present in the fetched data
  return [
    {
      id: "true",
      label: "Yes",
      totalCount: unfiltered["true"] ?? 0,
      filteredCount: filtered["true"],
    },
    {
      id: "false",
      label: "No",
      totalCount: unfiltered["false"] ?? 0,
      filteredCount: filtered["false"],
    },
  ];
}

const getPrimaryTopic = (topic: TopicDTO) => {
  return `${topic.title || topic.ngrams[0]}`; // boolean-or to catch empty string
};

function isPlatform(s: string): s is keyof typeof platforms {
  return s in platforms;
}

function getFilterCounts(
  summaries: ConversationFilterSummaryDTO[],
  filters: ConversationFilters,
) {
  return {
    narratives: getCountsForFilter(summaries, filters, "narrative"),
    platforms: getCountsForFilter(summaries, filters, "platform"),
    domains: getCountsForFilter(summaries, filters, "domain"),
    coordination_cluster: getCountsForFilter(
      summaries,
      filters,
      "coordination_cluster",
    ),
    sentiments: getCountsForFilter(summaries, filters, "sentiment"),
    contains_threatening_language: getCountsForFilter(
      summaries,
      filters,
      "contains_threatening_language",
    ),
    subcategories: getCountsForFilter(
      summaries,
      filters,
      "insight_subcategory",
    ),
    countries_of_origin: getCountsForFilter(
      summaries,
      filters,
      "insight_country_of_origin",
    ),
    operating_countries: getCountsForFilter(
      summaries,
      filters,
      "insight_operating_country",
    ),
    state_associations: getCountsForFilter(
      summaries,
      filters,
      "insight_state_association",
    ),
    regions: getCountsForFilter(summaries, filters, "insight_region"),
    languages: getCountsForFilter(summaries, filters, "insight_language"),
    roles: getCountsForFilter(summaries, filters, "insight_role"),
  };
}

function getCountsForFilter(
  summaries: ConversationFilterSummaryDTO[],
  filters: ConversationFilters,
  key: FilterKey,
) {
  return summaries.reduce<{ [value: string]: number }>((counts, summary) => {
    const value = summary[key];
    if (value == null) {
      return counts;
    }

    if (typeof value === "string") {
      const count = counts[value] ?? 0;
      counts[value] = isSummaryIncluded(summary, filters)
        ? count + summary.total
        : count;
    } else if (typeof value === "boolean") {
      const k = String(value);
      const count = counts[k] ?? 0;
      counts[k] = isSummaryIncluded(summary, filters)
        ? count + summary.total
        : count;
    } else if (Array.isArray(value)) {
      value.forEach((v) => {
        if (v != null) {
          const k = String(v);
          const count = counts[k] ?? 0;
          counts[k] = isSummaryIncluded(summary, filters)
            ? count + summary.total
            : count;
        }
      });
    }
    return counts;
  }, {});
}

export function getCountsForMegaFilters(
  summaries: ConversationFilterSummaryDTO[],
  filters: ConversationFilters,
  filterGroups: InsightRiskDTO[],
) {
  /*
    for each megafilter, find the filter-summary object(s)
    which have filters matching that megafilter and add up
    the number of posts they describe; return this as a map
    of megafilters to counts so we can display the same bar
    we do for other filters
  */
  return summaries.reduce<{ [value: string]: number }>((counts, summary) => {
    filterGroups.forEach((group) => {
      /*
      A megafilter may contain multiple filter values of the same key. As long as a summary
      match with one of the values of all keys, it's total will be counted.
      */
      const matchByKey: Partial<Record<FilterKey, boolean>> = {};
      group.filters.forEach((filter) => {
        if (matchByKey[filter.key]) {
          return;
        }

        const value = summary[filter.key];
        if (value == null) {
          matchByKey[filter.key] = false;
        } else if (typeof value === "string") {
          if (filter.value.endsWith(":false")) {
            matchByKey[filter.key] = value !== filter.value.split(":")[0];
          } else {
            matchByKey[filter.key] = value === filter.value.split(":")[0];
          }
        } else if (typeof value === "boolean") {
          matchByKey[filter.key] = String(value) === filter.value;
        } else if (Array.isArray(value)) {
          if (filter.value.endsWith(":false")) {
            matchByKey[filter.key] = !value.includes(
              filter.value.split(":")[0],
            );
          } else {
            matchByKey[filter.key] = value.includes(filter.value.split(":")[0]);
          }
        } else {
          matchByKey[filter.key] = false;
        }
      });

      const isMatch = Object.values(matchByKey).every((value) => value);
      if (isMatch) {
        const count = counts[group.type_name] ?? 0;
        counts[group.type_name] = isSummaryIncluded(summary, filters)
          ? count + summary.total
          : count;
      }
    });

    return counts;
  }, {});
}

// remove any filters of a mega filter not present in this conversation
export function filterMegaFilters(
  filters: InsightRiskDTO["filters"],
  options: ConversationFilterOptions,
): InsightRiskDTO["filters"] {
  return filters.reduce<InsightRiskDTO["filters"]>((acc, filter) => {
    if (isVisibleFilterKey(filter.key)) {
      const filterType = filterTypeForQueryKey[filter.key];
      const possibleOptions = options[filterType];
      /* 
      Checks if any of the possible options' IDs are included in the filter value.
      This accounts for the filter value potentially having a ':true' or ':false' suffix (due to them being an inclusive/exclusive filter).
      */
      const filterExists = possibleOptions.some((f) =>
        filter.value.includes(f.id),
      );
      if (filterExists) acc.push(filter);
    } else {
      acc.push(filter);
    }
    return acc;
  }, []);
}

export const filterKeys = [
  ...Object.keys(filterTypeForQueryKey),
  "start_range",
  "end_range",
];
const megaFilterKeys = ["insight_risk"];

export function useClearAllFilters() {
  const [searchParams, setSearchParams] = useSearchParams();
  const hasFilters = filterKeys.some((key) => searchParams.has(key));

  const clearAll = useCallback(
    (opts?: NavigateOptions) => {
      setSearchParams((current) => {
        filterKeys.forEach((key) => current.delete(key));
        megaFilterKeys.forEach((key) => current.delete(key));
        return current;
      }, opts);
    },
    [setSearchParams],
  );

  return {
    clearAll,
    hasFilters,
  };
}
