import { styled } from "@linaria/react";
import { AxisBottom, AxisLeft, type TickRendererProps } from "@visx/axis";
import { curveMonotoneX } from "@visx/curve";
import { localPoint } from "@visx/event";
import { GridRows } from "@visx/grid";
import { Group } from "@visx/group";
import { scaleLinear, scaleTime } from "@visx/scale";
import { AreaClosed, Bar, Line, LinePath } from "@visx/shape";
import { Text } from "@visx/text";
import { useTooltip, useTooltipInPortal } from "@visx/tooltip";
import { bisector, extent, max, mean } from "d3-array";
import type { NumberValue } from "d3-scale";
import {
  useCallback,
  type FunctionComponent,
  type StyledComponent,
  type TouchEvent,
  type MouseEvent,
  type ComponentType,
  useRef,
  useEffect,
} from "react";

import ResponsiveContainer from "~/components/ResponsiveContainer";
import Tooltip, { TooltipPosition } from "~/components/charts/Tooltip";
import { getCSSValue, startDayTickFormatter } from "~/components/charts/utils";
import { text } from "~/styles/typography";
import { formatCompactNumber } from "~/utils/numberUtils";

const GRADIENT_ID = "fill-gradient";

const MINIMUM_SPACE_BETWEEN_TICK_LABELS = 10;

type Datum = {
  value: number;
  date: Date;
};

const leftTickFormatter = (value: NumberValue) => {
  return formatCompactNumber(Number(value));
};

const leftTick = (props: TickRendererProps) => {
  const { formattedValue, ...rest } = props;
  const x = -parseInt(getCSSValue("--spacing-lg"), 10);

  return (
    <Text {...rest} x={x}>
      {formattedValue}
    </Text>
  );
};

const bottomTick = (props: TickRendererProps) => {
  const { formattedValue, ...rest } = props;
  const y = parseInt(getCSSValue("--spacing-lg"), 10);

  return (
    <Text {...rest} textAnchor="middle" verticalAnchor="start" y={y}>
      {formattedValue}
    </Text>
  );
};

const LegendContainer = styled.foreignObject<{ averagecolor: string }>`
  figcaption {
    display: flex;
    justify-content: right;
    align-items: center;
    ${text.xs.regular};
    color: var(--text-color-tertiary);

    .average-line {
      width: 12px;
      border-bottom: 1px dashed ${(props) => props.averagecolor};
      height: 0;
      margin-right: var(--spacing-xs);
    }
  }
`;

const bisectDate = bisector<Datum, Date>((d) => new Date(d.date)).left;
const getDate = (d: Datum) => new Date(d.date);

const leftTickLabelProps = () => {
  return {
    fill: "var(--text-color-tertiary)",
    fontSize: "var(--font-size-text-xs)",
    textAnchor: "end",
    verticalAnchor: "middle",
  } as const;
};

const bottomTickLabelProps = () => {
  return {
    fill: "var(--text-color-tertiary)",
    fontSize: "var(--font-size-text-xs)",
    textAnchor: "middle",
  } as const;
};

const HoverTooltip = styled(Tooltip)`
  transform: translate(-50%, -100%);
  > div {
    background-color: var(--color-white);
    padding: 0;
  }
  > div::before {
    background-color: var(--color-white);
  }
`;

export interface TooltipProps {
  datum: Datum;
  dataCount: number;
  average?: number;
}
interface AreaChartProps extends StyledComponent {
  ariaLabelledby?: string;
  data: Datum[];
  fill?: string | string[];
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  showAverageValue?: boolean;
  stroke?: string;
  strokeAverageLine?: string;
  TooltipComponent?: ComponentType<TooltipProps>;
  useTooltips?: boolean;
  width: number;
}

function useHideOverlapLabels(data: Datum[], width: number) {
  const gRef = useRef<SVGGElement | null>(null);

  useEffect(() => {
    if (!gRef.current) return;
    const tspans = Array.from(gRef.current.querySelectorAll("tspan"));
    const nonOverlappingLabels: SVGTSpanElement[] = [];
    let lastNonOverlappingRight = 0;

    tspans.forEach((tspan, index) => {
      const { width: textWidth, left: xViewport } =
        tspan.getBoundingClientRect();
      const gBBox = gRef.current?.getBoundingClientRect();
      const xFromG = gBBox ? xViewport - gBBox.left : 0;

      const currentLabelLeft = xFromG;
      const currentLabelRight = xFromG + textWidth;

      if (
        index === 0 ||
        currentLabelLeft >
          lastNonOverlappingRight + MINIMUM_SPACE_BETWEEN_TICK_LABELS
      ) {
        nonOverlappingLabels.push(tspan);
        lastNonOverlappingRight = currentLabelRight;
      }
    });

    tspans.forEach((tspan) => {
      tspan.style.visibility = nonOverlappingLabels.includes(tspan)
        ? "visible"
        : "hidden";
    });
  }, [data, width]);

  return gRef;
}

function useAreaChart(props: AreaChartProps) {
  const {
    data,
    height,
    margin = { top: 0, right: 0, bottom: 0, left: 0 },
    showAverageValue = false,
    width,
  } = props;

  const { hideTooltip, showTooltip, tooltipData, tooltipLeft, tooltipTop } =
    useTooltip<Datum>();
  const { TooltipInPortal, containerRef } = useTooltipInPortal({
    scroll: true,
  });

  const innerWidth = Math.max(0, width - margin.left - margin.right);
  const innerHeight = Math.max(0, height - margin.top - margin.bottom);

  const dateScale = scaleTime({
    range: [margin.left, margin.left + innerWidth],
    domain: extent(data, (d) => d.date) as [Date, Date],
  });
  const valueScale = scaleLinear({
    range: [innerHeight + margin.top, margin.top],
    domain: [0, max(data, (d) => d.value) ?? 0],
  });

  const yAccessor = useCallback(
    (d: Datum) => valueScale(d.value),
    [valueScale],
  );
  const xAccessor = useCallback((d: Datum) => dateScale(d.date), [dateScale]);

  const averageValue = showAverageValue
    ? Math.round(mean(data, (d) => d.value) ?? 0)
    : undefined;

  const handleTooltip = useCallback(
    (event: TouchEvent<SVGRectElement> | MouseEvent<SVGRectElement>) => {
      const { x } = localPoint(event) || { x: 0 };
      const x0 = dateScale.invert(x);
      const index = bisectDate(data, x0, 1);
      const d0 = data[index - 1];
      const d1 = data[index];
      let d = d0;
      if (d1 && getDate(d1)) {
        d =
          x0.getTime() - getDate(d0).getTime() >
          getDate(d1).getTime() - x0.getTime()
            ? d1
            : d0;
      }
      showTooltip({
        tooltipData: d,
        tooltipLeft: xAccessor(d),
        tooltipTop: yAccessor(d),
      });
    },
    [dateScale, data, showTooltip, xAccessor, yAccessor],
  );

  const gRef = useHideOverlapLabels(data, innerWidth);

  return {
    averageValue,
    containerRef,
    dateScale,
    gRef,
    handleTooltip,
    hideTooltip,
    innerHeight,
    innerWidth,
    margin,
    tooltipData,
    tooltipLeft,
    tooltipTop,
    TooltipInPortal,
    valueScale,
    xAccessor,
    yAccessor,
  };
}

const AreaChart: FunctionComponent<AreaChartProps> = (props) => {
  const {
    ariaLabelledby,
    className,
    data,
    fill,
    stroke = "var(--color-black)",
    strokeAverageLine = "var(--color-black)",
    style,
    TooltipComponent,
    useTooltips = false,
  } = props;

  const {
    averageValue,
    containerRef,
    dateScale,
    gRef,
    handleTooltip,
    hideTooltip,
    innerHeight,
    innerWidth,
    margin,
    tooltipData,
    tooltipLeft,
    tooltipTop,
    TooltipInPortal,
    valueScale,
    xAccessor,
    yAccessor,
  } = useAreaChart(props);

  return (
    <div ref={containerRef} className={className} style={style}>
      <svg
        aria-labelledby={ariaLabelledby}
        height="100%"
        role="group"
        width="100%"
      >
        {averageValue ? (
          <LegendContainer
            averagecolor={strokeAverageLine}
            height={innerHeight}
            width={innerWidth}
            x={margin.left}
          >
            <figure>
              <figcaption>
                <div className="average-line" role="presentation" />
                {`Avg: ${averageValue}`}
              </figcaption>
            </figure>
          </LegendContainer>
        ) : null}
        {fill && typeof fill !== "string" ? (
          <defs>
            <linearGradient gradientTransform="rotate(90)" id={GRADIENT_ID}>
              {fill.map((color, i) => {
                const percentage = 100 / (fill.length - 1);
                return (
                  <stop
                    key={color + i}
                    offset={`${percentage * i}%`}
                    stopColor={color}
                  />
                );
              })}
            </linearGradient>
          </defs>
        ) : null}
        <GridRows
          left={margin.left}
          numTicks={3}
          scale={valueScale}
          width={innerWidth}
        />
        <AreaClosed<Datum>
          curve={curveMonotoneX}
          data={data}
          fill={typeof fill === "string" ? fill : `url(#${GRADIENT_ID})`}
          stroke="var(--color-transparent)"
          strokeWidth={1}
          x={xAccessor}
          y={yAccessor}
          yScale={valueScale}
        />
        <LinePath
          curve={curveMonotoneX}
          data={data}
          stroke={stroke}
          strokeWidth={1}
          x={xAccessor}
          y={yAccessor}
        />
        <AxisLeft
          hideAxisLine
          hideTicks
          left={margin.left}
          numTicks={3}
          scale={valueScale}
          tickComponent={leftTick}
          tickFormat={leftTickFormatter}
          tickLabelProps={leftTickLabelProps}
        />
        <g ref={gRef}>
          <AxisBottom
            hideAxisLine
            hideTicks
            numTicks={3}
            scale={dateScale}
            tickComponent={bottomTick}
            tickFormat={startDayTickFormatter}
            tickLabelProps={bottomTickLabelProps}
            top={innerHeight + margin.top}
          />
        </g>
        {averageValue ? (
          <Line
            from={{ x: margin.left, y: valueScale(averageValue) }}
            stroke={strokeAverageLine}
            strokeDasharray="5"
            strokeWidth={1}
            to={{ x: margin.left + innerWidth, y: valueScale(averageValue) }}
          />
        ) : null}
        {useTooltips ? (
          <Bar
            aria-label="show tool tip"
            fill="transparent"
            height={innerHeight}
            onMouseLeave={hideTooltip}
            onMouseMove={handleTooltip}
            onTouchMove={handleTooltip}
            onTouchStart={handleTooltip}
            width={innerWidth}
            x={margin.left}
            y={margin.top}
          />
        ) : null}
        {useTooltips && tooltipData && (
          <Group pointerEvents="none">
            <Line
              from={{ x: tooltipLeft, y: tooltipTop }}
              stroke="var(--color-utility-blue-700)"
              strokeWidth={2}
              to={{
                x: tooltipLeft,
                y: innerHeight + margin.top,
              }}
            />
            <circle
              cx={tooltipLeft}
              cy={tooltipTop}
              fill="var(--color-utility-blue-700)"
              r={3}
              stroke="var(--color-white)"
            />
          </Group>
        )}
      </svg>
      {useTooltips && tooltipData && (
        <TooltipInPortal
          applyPositionStyle
          left={tooltipLeft}
          offsetLeft={0}
          offsetTop={-15}
          style={{
            pointerEvents: "none",
          }}
          top={tooltipTop}
        >
          <HoverTooltip data-position={TooltipPosition.Top} role="tooltip">
            {TooltipComponent ? (
              <TooltipComponent
                average={averageValue}
                dataCount={data.length}
                datum={tooltipData}
              />
            ) : (
              tooltipData.value
            )}
          </HoverTooltip>
        </TooltipInPortal>
      )}
    </div>
  );
};

export default ResponsiveContainer(AreaChart, undefined, {
  position: "relative",
});
