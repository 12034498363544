import { styled } from "@linaria/react";
import { useCallback, type FunctionComponent } from "react";
import type { PressEvent } from "react-aria";

import IconButton, {
  ButtonKind,
  ButtonSize,
} from "~/components/library/IconButton";

import { infoByEdgeType, infoByNodeType } from "../../constants";
import { type AllAvailableEdges, type NodeType } from "../../types";

import BaseProfile from "./BaseProfile";

export interface EdgeProfileProps {
  conversationId: string;
  type: "genericEdge";
  onDismiss: () => void;
  edgeType: AllAvailableEdges;
  actionNodeId: string;
  receiverNodeId: string;
  onIconClick: (id: string) => void;
}

const StyledBaseProfile = styled(BaseProfile)`
  #profile-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

function useEdgeProfile(props: EdgeProfileProps) {
  const { actionNodeId, edgeType, onDismiss, receiverNodeId, onIconClick } =
    props;
  const displayableData = {
    actionNodeId,
    receiverNodeId,
    edgeInfo: infoByEdgeType[edgeType],
  };

  const handleIconClick = useCallback(
    (e: PressEvent) => {
      const id = e.target.getAttribute("data-for");
      if (!id) return;
      onIconClick(id);
    },
    [onIconClick],
  );

  return {
    data: displayableData,
    handleIconClick,
    onDismiss,
  };
}

export const EdgeProfile: FunctionComponent<EdgeProfileProps> = (props) => {
  const { data, handleIconClick, onDismiss } = useEdgeProfile(props);

  return (
    <StyledBaseProfile heading="Edge" onDismiss={onDismiss}>
      <IconButton
        aria-label={`open ${data?.edgeInfo.actionNodeType} node dialog`}
        data-for={data?.actionNodeId}
        data-kind={ButtonKind.Tertiary}
        data-size={ButtonSize.md}
        data-type={data?.edgeInfo.actionNodeType}
        icon={
          infoByNodeType[data?.edgeInfo.actionNodeType as NodeType].iconName
        }
        onPress={handleIconClick}
        type="button"
      />
      <span>{data?.edgeInfo.text}</span>
      <IconButton
        aria-label={`open ${data?.edgeInfo.receiverNodeType} node dialog`}
        data-for={data?.receiverNodeId}
        data-kind={ButtonKind.Tertiary}
        data-size={ButtonSize.md}
        data-type={data?.edgeInfo.receiverNodeType}
        icon={
          infoByNodeType[data?.edgeInfo.receiverNodeType as NodeType].iconName
        }
        onPress={handleIconClick}
        type="button"
      />
    </StyledBaseProfile>
  );
};
