import { ParentSize } from "@visx/responsive";
import {
  type ComponentType,
  type CSSProperties,
  type FunctionComponent,
} from "react";

type Size = {
  width: number;
  height: number;
};

const ResponsiveContainer = <T extends Size>(
  Component: ComponentType<T>,
  debounceTime?: number,
  style?: CSSProperties,
) => {
  const ResponsiveWrapper: FunctionComponent<Omit<T, keyof Size>> = (props) => {
    return (
      <ParentSize debounceTime={debounceTime} parentSizeStyles={style}>
        {({ width, height }) => (
          <Component {...(props as T)} height={height} width={width} />
        )}
      </ParentSize>
    );
  };

  return ResponsiveWrapper;
};

export default ResponsiveContainer;
