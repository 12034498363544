import { styled } from "@linaria/react";
import type { FunctionComponent } from "react";

import Field from "~/components/Field";
import AreaChart, {
  type TooltipProps,
} from "~/components/charts/AreaChart/AreaChart";
import { getCSSValue } from "~/components/charts/utils";
import Icon from "~/components/library/Icon";
import type { NrmsConversationThemeMetricsDTO } from "~/dto/nrmsConversationThemeMetrics";
import { text } from "~/styles/typography";
import { formatDate } from "~/utils/datetime";
import { pluralize } from "~/utils/stringUtils";

interface NarrativeAreaChartProps {
  ariaLabelledby?: string;
  volume_history: NrmsConversationThemeMetricsDTO["volume_history"];
}

const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  padding: var(--spacing-lg);
  border-radius: var(--border-radius-xs);
  color: var(--color-black);

  > span {
    margin-left: var(--spacing-xs);
    ${text.sm.regular}
    border-bottom: 1px solid var(--color-utility-gray-100);
    padding-bottom: var(--spacing-xs);
  }

  > div:nth-child(2) {
    padding-top: var(--spacing-xs);
  }

  > div {
    display: flex;
    flex-direction: row-reverse;
    padding-left: var(--spacing-xs);
    padding-right: var(--spacing-md);
    gap: var(--spacing-xs);
    align-items: center;

    dd {
      min-width: 20px;
      text-align: end;
      ${text.sm.bold}
    }
    dt {
      flex: 1;
      ${text.sm.regular}
    }
  }
`;

const TooltipComponent: FunctionComponent<TooltipProps> = (props) => {
  const { datum, dataCount, average } = props;

  const difference = datum.value - Math.round(average ?? 0);
  const word = difference > 0 ? "Above" : "Below";
  const sign = difference > 0 ? "+" : "";
  const postText = pluralize(datum.value, "Post", undefined, true);

  return (
    <TooltipContent>
      <span>{formatDate(datum.date)}</span>
      <Field name={postText} value={datum.value} />
      <Field
        name={`${word} ${dataCount} day avg`}
        value={`${sign + difference}`}
      />
    </TooltipContent>
  );
};

const NoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius-xs);
  background: var(--background-color-tertiary);
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: var(--spacing-md);

  > span:nth-child(2) {
    margin-top: var(--spacing-xl);
    ${text.sm.medium}
    color: var(--color-utility-gray-500)
  }

  > span:last-child {
    margin-top: var(--spacing-xs);
    ${text.xs.regular}
    color: var(--color-utility-gray-400)
  }
`;

const SearchIcon = styled(Icon)`
  width: 28px;
  height: 28px;
  color: var(--color-utility-gray-400);
`;
const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-xl);
  background-color: var(--background-color-primary);
  border-radius: var(--border-radius-full);
`;

const ChartContainer = styled.div`
  border-radius: var(--border-radius-xs);
  width: 100%;
  background-color: var(--background-color-tertiary);
`;

const NarrativeAreaChart: FunctionComponent<NarrativeAreaChartProps> = (
  props,
) => {
  const { ariaLabelledby, volume_history } = props;

  if (!volume_history?.length) {
    return (
      <NoDataContainer>
        <IconContainer>
          <SearchIcon family="untitled" name="search-refraction" />
        </IconContainer>
        <span>No Relevant Data</span>
        <span>There are no significant narratives around this topic</span>
      </NoDataContainer>
    );
  }

  const data = volume_history.map((d) => ({
    value: d.volume ?? 0,
    date: d.date ? new Date(d.date) : new Date(),
  }));

  const margin = {
    top: parseInt(getCSSValue("--spacing-4xl"), 10),
    right: parseInt(getCSSValue("--spacing-6xl"), 10),
    bottom: parseInt(getCSSValue("--spacing-4xl"), 10),
    left: parseInt(getCSSValue("--spacing-6xl"), 10),
  };

  return (
    <ChartContainer>
      <AreaChart
        ariaLabelledby={ariaLabelledby}
        data={data}
        fill={[
          "var(--color-utility-blue-700)",
          "var(--color-utility-blue-light-300)",
        ]}
        margin={margin}
        showAverageValue
        stroke="var(--color-utility-blue-700)"
        strokeAverageLine="var(--color-utility-blue-700)"
        TooltipComponent={TooltipComponent}
        useTooltips
      />
    </ChartContainer>
  );
};

export default NarrativeAreaChart;
