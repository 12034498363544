import {
  type SeverityLevel,
  captureException,
  captureMessage,
} from "@sentry/react";

import { type LoggerFunction, LogLevel } from "./types";

const logLevelToSeverity: Record<LogLevel, SeverityLevel> = {
  [LogLevel.Error]: "error",
  [LogLevel.Warn]: "warning",
  [LogLevel.Log]: "log",
  [LogLevel.Debug]: "debug",
};

const sentryLog: LoggerFunction = (level: LogLevel, ...msg: unknown[]) => {
  const severity = logLevelToSeverity[level];
  const firstMsg = msg[0];
  if (level === LogLevel.Error || firstMsg instanceof Error) {
    captureException(firstMsg, {
      level: severity,
    });
  } else {
    captureMessage(formatMessage(msg), severity);
  }
};

function formatMessage(msg: unknown[]) {
  return msg
    .map((m) => (typeof m === "object" ? JSON.stringify(m) : String(m)))
    .join(" ");
}

export { sentryLog };
