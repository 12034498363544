import { styled } from "@linaria/react";
import {
  type ComponentPropsWithoutRef,
  type ReactNode,
  type FunctionComponent,
  useLayoutEffect,
  useState,
  type ClassAttributes,
  type HTMLAttributes,
  useRef,
  useCallback,
} from "react";

import {
  AdditionalActionsPanel,
  CopyToClipboard,
  OpenInNewTab,
} from "./AdditionalActionsPanel";
import Button, { ButtonKind, ButtonSize } from "./library/Button";
import Popover from "./library/Popover";

type LineClampProps = {
  /**
   * The number of lines to clamp to.
   * @default 2
   */
  lines?: number;
};

type TruncatedBaseProps = Omit<ComponentPropsWithoutRef<"span">, "children"> & {
  /** When provided, the "open in new tab" button will be displayed and link to the provided href. */
  href?: string;
};

type TruncatedTextProps = TruncatedBaseProps & {
  /** The text to display. */
  children: string;

  text?: never;
};

type TruncatedNodeProps = TruncatedBaseProps & {
  /** The node to display. */
  children: ReactNode;

  /** The text to display. */
  text: string;
};

type TruncatedProps = TruncatedTextProps | TruncatedNodeProps;

export const Ellipsized = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const LineClamp = styled.span<LineClampProps>`
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${(props) => props.lines ?? 2};
`;

const TruncatedContainer = styled.span`
  display: flex;
  overflow: hidden;
`;

const TruncatedText = styled(Ellipsized)`
  text-overflow: clip;
  flex-grow: 1;

  ${TruncatedContainer} & {
    align-self: center;
  }
`;

const TruncatePopover = styled(Popover)`
  --truncate-text-button-width: 24px;

  display: flex;
  width: var(--truncate-text-button-width);
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
`;

const TruncatePopoverContent = styled(AdditionalActionsPanel)`
  align-items: flex-start;
`;

const FullText = styled.div`
  width: max-content;
  max-width: 600px;
  max-height: 300px;
  overflow: auto;
`;

const TruncateIcon = () => (
  <svg
    fill="none"
    height="6"
    viewBox="0 0 14 6"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 3C0 1.34315 1.34315 0 3 0H11C12.6569 0 14 1.34315 14 3V3C14 4.65685 12.6569 6 11 6H3C1.34315 6 0 4.65685 0 3V3Z"
      fill="var(--color-gray-200)"
    />
    <circle cx="4" cy="3" fill="var(--background-color-brand-solid)" r="1" />
    <circle cx="7" cy="3" fill="var(--background-color-brand-solid)" r="1" />
    <circle cx="10" cy="3" fill="var(--background-color-brand-solid)" r="1" />
  </svg>
);

interface PopoverProps {
  text: string;
  href?: string;
}

const FullTextPopover: FunctionComponent<PopoverProps> = (props) => {
  const { text, href } = props;
  return (
    <TruncatePopover triggerText={<TruncateIcon />}>
      <TruncatePopoverContent>
        <FullText>{text}</FullText>
        <CopyToClipboard text={text} />
        {href && <OpenInNewTab href={href} />}
      </TruncatePopoverContent>
    </TruncatePopover>
  );
};

function useIsTruncated() {
  const [isTruncated, setIsTruncated] = useState(false);
  const ref = useRef<HTMLSpanElement>(null);

  useLayoutEffect(() => {
    const element = ref.current;
    if (!element) {
      return;
    }

    const checkIsTruncated = () => {
      setIsTruncated(element.scrollWidth > element.clientWidth);
    };

    checkIsTruncated();

    let resizeObserver: ResizeObserver | null = null;
    let mutationObserver: MutationObserver | null = null;

    if (typeof ResizeObserver !== "undefined") {
      resizeObserver = new ResizeObserver(checkIsTruncated);
      resizeObserver.observe(element);
    }
    if (typeof MutationObserver !== "undefined") {
      mutationObserver = new MutationObserver(checkIsTruncated);
      mutationObserver.observe(element, {
        subtree: true,
        characterData: true,
      });
    }

    return () => {
      resizeObserver?.disconnect();
      mutationObserver?.disconnect();
    };
  }, []);

  return { isTruncated, ref };
}

function useObserveTruncatedStyles() {
  const ref = useRef<HTMLSpanElement>(null);

  useLayoutEffect(() => {
    if (process.env.NODE_ENV === "development") {
      const element = ref.current;
      if (!element) {
        return;
      }

      const displayStyle = window.getComputedStyle(element).display;
      if (!["flex", "inline-flex"].includes(displayStyle)) {
        /* eslint-disable-next-line no-console -- this only runs in development */
        console.warn(
          "Truncated component should be used with flex container to avoid unexpected behavior.",
        );
      }
    }
  }, []);

  return { ref };
}

export const Truncated: FunctionComponent<TruncatedProps> = (props) => {
  const { children, text, href, ...spanProps } = props;
  const fullText = text ?? children;

  const { isTruncated, ref } = useIsTruncated();
  const { ref: containerRef } = useObserveTruncatedStyles();

  return (
    <TruncatedContainer ref={containerRef} {...spanProps}>
      <TruncatedText ref={ref}>{children}</TruncatedText>
      {isTruncated && (
        <FullTextPopover aria-label="Show all" href={href} text={fullText} />
      )}
    </TruncatedContainer>
  );
};

export const TruncatedWithoutPopover: FunctionComponent<
  ComponentPropsWithoutRef<"span">
> = (props) => {
  const { children, ...spanProps } = props;
  const { isTruncated, ref } = useIsTruncated();
  const { ref: containerRef } = useObserveTruncatedStyles();

  return (
    <TruncatedContainer ref={containerRef} {...spanProps}>
      <TruncatedText ref={ref}>{children}</TruncatedText>
      {isTruncated && (
        <span>
          <TruncateIcon />
        </span>
      )}
    </TruncatedContainer>
  );
};

const ExpandMinimizeButton = styled(Button)`
  width: fit-content;
`;

type ExpandableClampTextProps = ClassAttributes<HTMLSpanElement> &
  HTMLAttributes<HTMLSpanElement> &
  LineClampProps;

export const ExpandableClampText: FunctionComponent<
  ExpandableClampTextProps
> = (props) => {
  const { lines, children } = props;
  const contentRef = useRef<HTMLDivElement>(null);
  const [isClamped, setClamped] = useState(false);
  const [isExpanded, setExpanded] = useState(false);

  useLayoutEffect(() => {
    /* Needed to ensure that clamp buttons will be removed when text changes*/
    setExpanded(false);
    setClamped(false);

    function handleResize() {
      if (contentRef.current) {
        const { current } = contentRef;

        if (lines === 1) {
          setClamped(current.scrollHeight > current.clientHeight);
        } else {
          const computedStyle = window.getComputedStyle(current);
          const lineHeight = parseFloat(computedStyle.lineHeight);
          setClamped(
            current.scrollHeight > current.clientHeight &&
              current.clientHeight > lineHeight,
            /* 
            for a single non-clamped line, the scrollHeight sometimes briefly exceeds the clientHeight before they equal. This would result
            in isClamped being true, when it should be false. By checking the clientHeight against the lineHeight, we can check if 
            it actually exceeds more than a single line.
            */
          );
        }
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);

    /* Need children as dependency or else clamp buttons will persist */
  }, [children, lines]);

  const expandTexts = useCallback(() => setExpanded(true), []);
  const minimizeTexts = useCallback(() => setExpanded(false), []);

  return (
    <>
      {isExpanded ? (
        <span aria-expanded="true">{children}</span>
      ) : (
        <LineClamp ref={contentRef} aria-expanded="false" lines={lines}>
          {children}
        </LineClamp>
      )}

      {isClamped && (
        <ExpandMinimizeButton
          aria-label={isExpanded ? "Minimize texts" : "Expand texts"}
          data-kind={ButtonKind.Link}
          data-size={ButtonSize.sm}
          onPress={isExpanded ? minimizeTexts : expandTexts}
        >
          {isExpanded ? "Show Less Text" : "Show Full Text"}
        </ExpandMinimizeButton>
      )}
    </>
  );
};

const TruncatedClampContainer = styled.span<{ lines?: number }>`
  display: flex;
  flex-direction: row;
  height: ${(props) => props.lines || 2}lh;
  overflow: hidden;

  > span:first-child {
    width: auto;
  }

  .popover_container {
    align-items: flex-end;
    display: flex;
    width: fit-content;
  }
`;

type TruncatedLineClampTextProps = HTMLAttributes<HTMLSpanElement> &
  LineClampProps &
  PopoverProps;

export const TruncatedLineClampText: FunctionComponent<
  TruncatedLineClampTextProps
> = (props) => {
  const { children, href, lines, text, ...rest } = props;
  const outerRef = useRef<HTMLSpanElement>(null);
  const [isClamped, setIsClamped] = useState(false);

  useLayoutEffect(() => {
    setIsClamped(false);
    function checkIsClamped() {
      if (!outerRef.current) return;
      const { current: outerContainer } = outerRef;
      setIsClamped(outerContainer.scrollHeight > outerContainer.clientHeight);
    }
    checkIsClamped();

    let resizeObserver: ResizeObserver | null = null;
    if (typeof ResizeObserver !== "undefined") {
      resizeObserver = new ResizeObserver(checkIsClamped);
      resizeObserver.observe(document.body);
    }

    return () => {
      resizeObserver?.disconnect();
    };
  }, [children]);

  return (
    <TruncatedClampContainer ref={outerRef} lines={lines} {...rest}>
      <span>{children}</span>
      {isClamped && (
        <span className="popover_container">
          <FullTextPopover href={href} text={text} />
        </span>
      )}
    </TruncatedClampContainer>
  );
};
