export function formatLargeNumber(
  number: number,
  options: Intl.NumberFormatOptions = {},
) {
  return new Intl.NumberFormat("en-US", {
    notation: "compact",
    compactDisplay: "short",
    maximumSignificantDigits: 3,
    ...options,
  }).format(number);
}

/**
 * Formats a number as a percentage string (e.g., "50%").
 * Useful for displaying percentages concisely.
 */
export function formatPercentage(
  number: number,
  options: Intl.NumberFormatOptions = {},
) {
  return new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
    style: "percent",
    ...options,
  }).format(number);
}

/**
 * Formats a number into a compact string (e.g., "1K" for 1000).
 * Useful for concise display of large numbers where space is limited.
 */
export function formatCompactNumber(number: number) {
  return new Intl.NumberFormat("en-US", {
    notation: "compact",
  }).format(number);
}
