import { styled } from "@linaria/react";
import { scaleLinear } from "@visx/scale";
import { Bar } from "@visx/shape";
import { type FunctionComponent } from "react";

import { getCSSValue } from "~/components/charts/utils";
import { text } from "~/styles/typography";
import { formatCompactNumber, formatPercentage } from "~/utils/numberUtils";
import { pluralize } from "~/utils/stringUtils";

interface ThemeRadarBarProps {
  data: { name: string; value: number; color: string }[];
  total: number;
}

type BarData = ThemeRadarBarProps["data"][0] & { width: number; x: number };

const ChartWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;
`;

const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  ${text.sm.regular}
  color: var(--text-color-disabled);
`;

const SvgWrapper = styled.svg`
  width: 100%;
  height: var(--spacing-lg);
  border-radius: var(--border-radius-full);
  overflow: hidden;
  flex-shrink: 0;
`;

const useThemeRadarBar = (props: ThemeRadarBarProps) => {
  const { data, total } = props;
  const height = getCSSValue("--spacing-lg");
  const sumValues = data.reduce((acc, { value }) => acc + value, 0);
  const xScale = scaleLinear({
    domain: [0, total],
    range: [0, 100],
  });

  const barData = data.reduce<BarData[]>((acc, datum) => {
    const barWidth = xScale(datum.value);
    const previousWidth =
      acc.length > 0 ? acc[acc.length - 1].x + acc[acc.length - 1].width : 0;

    acc.push({ ...datum, width: barWidth, x: previousWidth });
    return acc;
  }, []);

  const allThemesText = `All Themes - ${formatCompactNumber(
    sumValues,
  )} ${pluralize(sumValues, "Post", undefined, true)} (
    ${formatPercentage(sumValues / total)})`;

  const totalConversationText = `Total Conversation - ${formatCompactNumber(
    total,
  )} ${pluralize(total, "Post", undefined, true)} `;

  return {
    allThemesText,
    barData,
    height,
    totalConversationText,
  };
};

const ThemeRadarBar: FunctionComponent<ThemeRadarBarProps> = (props) => {
  const { allThemesText, barData, height, totalConversationText } =
    useThemeRadarBar(props);

  return (
    <ChartWrapper>
      <TextWrapper>
        <span>{allThemesText}</span>
        <span>{totalConversationText}</span>
      </TextWrapper>
      <SvgWrapper preserveAspectRatio="none" viewBox="0 0 100 12">
        <Bar
          fill="var(--color-utility-gray-300)"
          height={height}
          width="100%"
          x={0}
          y={0}
        />
        {barData.map(({ name, color, width, x }) => (
          <Bar
            key={name}
            fill={color}
            height={height}
            width={width}
            x={x}
            y={0}
          />
        ))}
      </SvgWrapper>
    </ChartWrapper>
  );
};

export default ThemeRadarBar;
