import type { Platform } from "~/constants";
import fourchanFilled from "~/images/filled/4chan.svg?raw";
import eightkunFilled from "~/images/filled/8kun.svg?raw";
import blogFilled from "~/images/filled/blog.svg?raw";
import blueskyFilled from "~/images/filled/bluesky.svg?raw";
import facebookFilled from "~/images/filled/facebook.svg?raw";
import gabFilled from "~/images/filled/gab.svg?raw";
import gettrFilled from "~/images/filled/gettr.svg?raw";
import kiwifarmsFilled from "~/images/filled/kiwifarms.svg?raw";
import meweFilled from "~/images/filled/mewe.svg?raw";
import mindsFilled from "~/images/filled/minds.svg?raw";
import newsFilled from "~/images/filled/news.svg?raw";
import parlerFilled from "~/images/filled/parler.svg?raw";
import patriotsFilled from "~/images/filled/patriots.png";
import poalFilled from "~/images/filled/poal.svg?raw";
import redditFilled from "~/images/filled/reddit.svg?raw";
import telegramFilled from "~/images/filled/telegram.svg?raw";
import truthsocialFilled from "~/images/filled/truthsocial.svg?raw";
import twitterFilled from "~/images/filled/twitter.svg?raw";
import wimkinFilled from "~/images/filled/wimkin.svg?raw";

import type { AllAvailableEdges, NodeType } from "./types";

export const infoByNodeType: Record<
  NodeType,
  {
    iconName: UntitledIcon;
    label: string;
  }
> = {
  accounts: {
    iconName: "user-01",
    label: "Account",
  },
  domains: {
    iconName: "globe-03",
    label: "Domain",
  },
  hashtags: {
    iconName: "hash-02",
    label: "Hashtag",
  },
  links: {
    iconName: "link-03",
    label: "URL",
  },
  posts: {
    iconName: "message-text-square-01",
    label: "Post",
  },
  topics: {
    iconName: "message-text-square-01",
    label: "Topic",
  },
};

const textByEdgeType: Record<AllAvailableEdges, { english: string }> = {
  accountPosts: {
    english: "Account created Post",
  },
  accountCoordinationEvidenceAccounts: {
    english: "Account coordinated with Account",
  },
  accountLinks: {
    english: "Account posted URL",
  },
  accountHashtags: {
    english: "Account posted Hashtag",
  },
  domainPosts: {
    english: "Post contains Domain",
  },
  linkPosts: {
    english: "Post contains URL",
  },
  postHashtags: {
    english: "Post contains Hashtag",
  },
  accountRepostedHashtags: {
    english: "Account reposted Hashtag",
  },
  accountDomains: {
    english: "Account posted Domain",
  },
  accountQuotedPosts: {
    english: "Account quoted Post",
  },
  accountRepliedPosts: {
    english: "Account replied to Post",
  },
  accountRepostedDomains: {
    english: "Account reposted Domain",
  },
  accountRepostedLinks: {
    english: "Account reposted Link",
  },
  accountRepostedPosts: {
    english: "Account reposted Post",
  },
  postRepostedDomains: {
    english: "Post reposted Domain",
  },
  postRepostedLinks: {
    english: "Post reposted URL",
  },
  accountQuotedAccounts: {
    english: "Account quoted Account",
  },
  accountRepliedAccounts: {
    english: "Account replied to Account",
  },
  accountRepostedAccounts: {
    english: "Account reposted Account",
  },
  domainLinks: {
    english: "Link belongs to Domain",
  },
  postQuotedPosts: {
    english: "Post quoted Post",
  },
  postRepliedPosts: {
    english: "Post replied to Post",
  },
  postRepostedPosts: {
    english: "Post reposted Post",
  },
  postRepostedHashtags: {
    english: "Post reposted Hashtag",
  },
};

export const infoByEdgeType: Record<
  AllAvailableEdges,
  {
    text: string;
    actionNodeType: NodeType;
    receiverNodeType: NodeType;
  }
> = {
  accountPosts: {
    text: textByEdgeType.accountPosts.english,
    actionNodeType: "accounts",
    receiverNodeType: "posts",
  },
  accountCoordinationEvidenceAccounts: {
    text: textByEdgeType.accountCoordinationEvidenceAccounts.english,
    actionNodeType: "accounts",
    receiverNodeType: "accounts",
  },
  accountLinks: {
    text: textByEdgeType.accountLinks.english,
    actionNodeType: "accounts",
    receiverNodeType: "links",
  },
  accountHashtags: {
    text: textByEdgeType.accountHashtags.english,
    actionNodeType: "accounts",
    receiverNodeType: "hashtags",
  },
  domainPosts: {
    text: textByEdgeType.domainPosts.english,
    actionNodeType: "posts",
    receiverNodeType: "domains",
  },
  linkPosts: {
    text: textByEdgeType.linkPosts.english,
    actionNodeType: "posts",
    receiverNodeType: "links",
  },
  postHashtags: {
    text: textByEdgeType.postHashtags.english,
    actionNodeType: "posts",
    receiverNodeType: "hashtags",
  },
  accountRepostedHashtags: {
    text: textByEdgeType.accountRepostedHashtags.english,
    actionNodeType: "accounts",
    receiverNodeType: "hashtags",
  },
  accountDomains: {
    text: textByEdgeType.accountDomains.english,
    actionNodeType: "accounts",
    receiverNodeType: "domains",
  },
  accountQuotedPosts: {
    text: textByEdgeType.accountQuotedPosts.english,
    actionNodeType: "accounts",
    receiverNodeType: "posts",
  },
  accountRepliedPosts: {
    text: textByEdgeType.accountRepliedPosts.english,
    actionNodeType: "accounts",
    receiverNodeType: "posts",
  },
  accountRepostedDomains: {
    text: textByEdgeType.accountRepostedDomains.english,
    actionNodeType: "accounts",
    receiverNodeType: "domains",
  },
  accountRepostedLinks: {
    text: textByEdgeType.accountRepostedLinks.english,
    actionNodeType: "accounts",
    receiverNodeType: "links",
  },
  accountRepostedPosts: {
    text: textByEdgeType.accountRepostedPosts.english,
    actionNodeType: "accounts",
    receiverNodeType: "posts",
  },
  postRepostedDomains: {
    text: textByEdgeType.postRepostedDomains.english,
    actionNodeType: "posts",
    receiverNodeType: "domains",
  },
  postRepostedLinks: {
    text: textByEdgeType.postRepostedLinks.english,
    actionNodeType: "posts",
    receiverNodeType: "links",
  },
  accountQuotedAccounts: {
    text: textByEdgeType.accountQuotedAccounts.english,
    actionNodeType: "accounts",
    receiverNodeType: "accounts",
  },
  accountRepliedAccounts: {
    text: textByEdgeType.accountRepliedAccounts.english,
    actionNodeType: "accounts",
    receiverNodeType: "accounts",
  },
  accountRepostedAccounts: {
    text: textByEdgeType.accountRepostedAccounts.english,
    actionNodeType: "accounts",
    receiverNodeType: "accounts",
  },
  domainLinks: {
    text: textByEdgeType.domainLinks.english,
    actionNodeType: "links",
    receiverNodeType: "domains",
  },
  postQuotedPosts: {
    text: textByEdgeType.postQuotedPosts.english,
    actionNodeType: "posts",
    receiverNodeType: "posts",
  },
  postRepliedPosts: {
    text: textByEdgeType.postRepliedPosts.english,
    actionNodeType: "posts",
    receiverNodeType: "posts",
  },
  postRepostedPosts: {
    text: textByEdgeType.postRepostedPosts.english,
    actionNodeType: "posts",
    receiverNodeType: "posts",
  },
  postRepostedHashtags: {
    text: textByEdgeType.postRepostedHashtags.english,
    actionNodeType: "posts",
    receiverNodeType: "hashtags",
  },
};

/** maps of edges to human-readable strings.  since edges are bi-directional and
 * connect two types of user-selectable nodes, we have to have different text for
 * different menu options corresponding to the same edge */
export const edgeStrings: Record<NodeType, Record<string, string>> = {
  accounts: {
    accountPosts: "created a post",
    accountCoordinationEvidenceAccounts: "coordinated with account",
    accountLinks: "posted a URL",
    accountHashtags: "posted a hashtag",
    accountRepostedHashtags: "reposted a hashtag",
    accountQuotedPosts: "quoted a post",
    accountRepliedPosts: "replied to a post",
    accountRepostedPosts: "reposted a post",
    accountDomains: "posted a domain",
    accountRepostedDomains: "reposted a domain",
    accountRepostedLinks: "reposted a URL",
    accountRepliedAccounts: "replied to account",
    accountQuotedAccounts: "quoted account",
    accountRepostedAccounts: "reposted account",
  },
  domains: {
    // "domains-belongsto-url": "belongs to URL",
    domainPosts: "included in post",
    accountDomains: "posted by account",
    accountRepostedDomains: "reposted by account",
    postRepostedDomains: "reposted by post",
    domainLinks: "contains URL",
  },
  links: {
    linkPosts: "contained in post",
    accountLinks: "posted by account",
    accountRepostedLinks: "reposted by account",
    postRepostedLinks: "reposted by post",
    domainLinks: "belongs to domain",
  },
  posts: {
    accountPosts: "created by account",
    domainPosts: "containing domain",
    linkPosts: "containing URL",
    postHashtags: "contains hashtag",
    postRepostedLinks: "reposted a URL",
    accountQuotedPosts: "quoted by account",
    accountRepliedPosts: "replied by account",
    accountRepostedPosts: "reposted by account",
    postRepostedDomains: "reposted domain",
    postQuotedPosts: "quoted post",
    postRepostedPosts: "reposted post",
    postRepliedPosts: "replied to post",
    postRepostedHashtags: "reposted hashtag",
  },
  hashtags: {
    postHashtags: "belongs to post",
    postRepostedHashtags: "reposted by post",
    accountHashtags: "posted by account",
    accountRepostedHashtags: "reposted by account",
  },
  topics: {},
} as const;

export const nodeTypesByEdgeString: Record<string, NodeType[]> = Object.entries(
  edgeStrings,
)
  .flatMap(([nodeType, nested]) =>
    Object.keys(nested).map((key) => [key, nodeType]),
  )
  .reduce(
    (acc, [key, nodeType]) => ({
      ...acc,
      [key]: [...(acc[key] || []), nodeType as NodeType].filter(
        (nodeType) => !!nodeType,
      ),
    }),
    {} as Record<string, NodeType[]>,
  );

export const platformInfo: Partial<
  Record<Platform, { svg: string; color: string }>
> = {
  blog: {
    color: "#000000",
    svg: blogFilled,
  },
  // do not have icons for board
  // board: {
  //   color: "#000000",
  //   svg: boardFilled,
  // },
  eightkun: {
    color: "#239623",
    svg: eightkunFilled,
  },
  fourchan: {
    color: "#00AD00",
    svg: fourchanFilled,
  },
  gab: {
    color: "#21cf7a",
    svg: gabFilled,
  },
  gettr: {
    color: "#e40100",
    svg: gettrFilled,
  },
  kiwifarms: {
    color: "#FFFFFF",
    svg: kiwifarmsFilled,
  },
  mewe: {
    color: "#FFFFFF",
    svg: meweFilled,
  },
  minds: {
    color: "#FFFFFF",
    svg: mindsFilled,
  },
  news: {
    color: "#000000",
    svg: newsFilled,
  },
  parler: {
    color: "#CF203F",
    svg: parlerFilled,
  },
  poal: {
    color: "#000000",
    svg: poalFilled,
  },
  reddit: {
    color: "#FF4500",
    svg: redditFilled,
  },
  telegram: {
    color: "#38B0E3",
    svg: telegramFilled,
  },
  truthsocial: {
    color: "#FFFFFF",
    svg: truthsocialFilled,
  },
  twitter: {
    color: "#1da1f2",
    svg: twitterFilled,
  },
  wimkin: {
    color: "#E0001B",
    svg: wimkinFilled,
  },
  facebook: {
    color: "1877F2",
    svg: facebookFilled,
  },
  patriots: {
    color: "d4ba10",
    svg: patriotsFilled,
  },
  bluesky: {
    color: "1185fe",
    svg: blueskyFilled,
  },
};

export const listOfURLsNumber = [10, 30, 50, 100, 200, 300];
